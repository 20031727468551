import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Panel1 from "./panel1.svg";
import Panel2 from "./panel2.svg";
import Panel3 from "./panel3.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
} from "@material-ui/core";
import "./scrollStyle.css";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import OutlineButton from "../Buttons/Outline";
import generalStyle from "../../util/style";

function navAbout() {
  window.location = "/funding";
}

const panels = [
  {
    image: Panel1,
    title: "A fast and transparent way to receive and disburse donations",
    description: `The UNICEF CryptoFund is a new financial vehicle allowing UNICEF to receive, hold, and disburse cryptocurrency — a first for the UN — allowing UNICEF to explore what it means to operate in a digitally financed future.`,
    button: (
      <OutlineButton style={{ color: "#374EA2" }} onClick={navAbout}>
        Learn More
      </OutlineButton>
    ),
    accordion: [],
  },
  {
    image: Panel2,
    title: "We invest exclusively in open source technology solutions",
    description: `Through its investments, the CryptoFund generates value by strengthening communities of problem solvers and increasing open-source solutions that bring results for children and the world.`,
    accordion: [
      {
        summary: "Why is being open source good business?",
        details:
          "Open source business models have the ability to achieve widespread impact, opening doors to new talent and markets, and creating revenue opportunities for businesses – often, better than proprietary technology – in a secure, sustainable, and scalable manner. By encouraging our partners to also embrace open source, we believe we can generate exponential impact from the solutions we deliver.",
      },
      {
        summary: "Can a startup from any country apply?",
        details:
          "The UNICEF Innovation Fund and CryptoFund accept submissions from companies registered in UNICEF programme countries. This is to ensure funding and support for innovators on the ground, who are from markets traditionally overlooked by venture capital.",
      },
    ],
  },
  {
    image: Panel3,
    title: "The CryptoFund provides investments in bitcoin or ether",
    description: `By distributing funding in cryptocurrency, UNICEF, donors, recipients, and the public can track where funds are going and how it is being spent – providing an unprecedented level of transparency in the NGO and funding space.`,
    accordion: [
      {
        summary: "How much funding does the CryptoFund offer?",
        details:
          " The Innovation Fund and CryptoFund provide up to USD 100K in fiat and/or BTC or ETH equivalent in seed funding to startups developing software and data-driven solutions.",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.up("md")]: {
      // paddingTop: 200,
      // paddingBottom: 200,
      padding: 0,
    },
  },
  scroll: {
    [theme.breakpoints.up("md")]: {
      height: 550,
      overflowY: "scroll",
      scrollSnapType: "y proximity",
      scrollbarWidth: "none",
      // backgroundColor: "red"
    },
  },
  card: {
    marginTop: 200,
    marginBottom: 250,
    [theme.breakpoints.up("md")]: {
      // scrollSnapAlign: "start",
      marginTop: 0,
      marginBottom: 250,
    },
  },
  message: {
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
      padding: 28,
      backgroundColor: "#F2F4F6",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: 493,
      paddingRight: 0,
      // marginTop: "-2em",
      // zIndex: -1,
    },
  },
  button: {
    marginTop: 30,
  },
  imageHolder: {
    marginTop: -200,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  image: {
    maxWidth: "100%",
    height: 220,
    [theme.breakpoints.up("md")]: {
      height: 560,
    },
  },
  tileTitle: {
    marginBottom: 0,
    color: "#374EA2",
  },
  description: {
    marginBottom: 60,
    color: "#374EA2",
  },
  square: {
    height: 9,
    width: 9,
    background: "#374EA2",
    marginBottom: 15,
    marginRight: 10,
  },
  active: {
    transition: "background-color .3s",
    backgroundColor: "#CED1D5",
    marginBottom: 15,
    height: 9,
    width: 9,
    marginRight: 10,
  },
  accordionDesc: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      width: 550,
    },
  },
  desktopContainer: {
    // margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50%",
      marginBottom: "-20%",
    },
    [theme.breakpoints.between("md", 3000)]: {
      marginTop: "-30%",
      marginBottom: "-16%",
    },
    [theme.breakpoints.between(3000, "xl")]: {
      marginTop: "-15%",
      marginBottom: "-10%",
    },
    [theme.breakpoints.up(4500)]: {
      marginTop: "-10%",
      marginBottom: "-5%",
    },
  },
  transition: {
    transitionDuration: ".3s",
    transitionProperty: "background-color",
  },
}));

export function Accordions({ accordions }) {
  // const classes = useStyles();
  const [expanded, setExpanded] = React.useState(-1);
  const handleChange = (i) => (event, isExpanded) => {
    setExpanded(isExpanded ? i : -1);
  };

  return accordions.map((accordion, i) => {
    const style =
      i === 0
        ? {
            marginTop: -30,
            backgroundColor: "Transparent",
            boxShadow: "unset",
            borderTop: "1px solid rgba(55, 78, 162, 0.5)",
            borderBottom: "1px solid rgba(55, 78, 162, 0.5)",
          }
        : {
            backgroundColor: "Transparent",
            boxShadow: "unset",
            borderBottom: "1px solid rgba(55, 78, 162, 0.5)",
          };
    return (
      <Accordion
        key={i}
        square={true}
        style={style}
        expanded={expanded === i}
        onChange={handleChange(i)}
      >
        <AccordionSummary
          expandIcon={
            expanded === i ? (
              <CloseIcon style={{ color: "#1CABE2" }} />
            ) : (
              <AddIcon style={{ color: "#1CABE2" }} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ paddingLeft: 1 }}
        >
          <p
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              fontSize: "18px",
              fontWeight: "normal",
              lineHeight: "28px",
              color: "#1CABE2",
              fontFamily: "Roboto",
            }}
          >
            {accordion?.summary.toString()}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <p style={{ marginTop: 0, color: "#2D2926", fontSize: 18 }}>
            {accordion?.details}
          </p>
        </AccordionDetails>
      </Accordion>
    );
  });
}

function PanelDesktop(props) {
  const classes = useStyles();
  const style = generalStyle();
  return (
    <Container maxWidth={"lg"}>
      <Grid container className={classes.card}>
        <Grid item md={7} className={classes.message}>
          <div className={classes.imageHolder}>
            <img src={props.image} className={classes.image} />
          </div>
        </Grid>
        <Grid item md={5} className={classes.message}>
          <h4 className={`${classes.tileTitle} ${style.title}`}>
            {props.title}
          </h4>
          <p className={`${classes.description} ${style.description}`}>
            {props.description}
          </p>
          {props?.button}
          {props.accordion ? (
            <Accordions accordions={props.accordion} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

function PanelMobile(props) {
  const classes = useStyles();
  const style = generalStyle();
  return (
    <Grid container className={classes.card}>
      <Grid item alignContent={"center"} xs={12} className={classes.message}>
        <div className={classes.imageHolder}>
          <img src={props.image} className={classes.image} />
        </div>
        <h4
          className={style.title}
          style={{ color: "#374EA2", marginBottom: 0 }}
        >
          {props.title}
        </h4>
        <p className={`${classes.description} ${style.description}`}>
          {props.description}
        </p>
        {props?.button}
        {props.accordion ? <Accordions accordions={props.accordion} /> : <></>}
      </Grid>
    </Grid>
  );
}

export default function (props) {
  const classes = useStyles();
  const refScroll = useRef();
  const [indicator, setIndicator] = useState([false, false, false]);
  const updateActivation = (index) => {
    let t = [false, false, false];
    t[index] = true;
    setIndicator(t);
  };
  useEffect(() => {
    document.getElementById("root").addEventListener("scroll", (ev) => {
      if (refScroll.current) {
        if (
          document.getElementById("root").scrollTop >=
            refScroll.current.offsetTop &&
          document.getElementById("root").scrollTop <=
            refScroll.current.offsetTop +
              refScroll.current.offsetHeight / 3 -
              100
        ) {
          updateActivation(0);
        } else if (
          document.getElementById("root").scrollTop >
            refScroll.current.offsetTop +
              refScroll.current.offsetHeight / 3 -
              100 &&
          document.getElementById("root").scrollTop <=
            refScroll.current.offsetTop +
              (refScroll.current.offsetHeight * 2) / 3 -
              100
        ) {
          updateActivation(1);
        } else if (
          document.getElementById("root").scrollTop >
            refScroll.current.offsetTop +
              (refScroll.current.offsetHeight * 2) / 3 -
              100 &&
          document.getElementById("root").scrollTop <=
            refScroll.current.offsetTop + refScroll.current.offsetHeight
        ) {
          updateActivation(2);
        }
      }
    });
  }, []);
  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item align={"right"} md className={"sticky"}>
          <div className={indicator[0] ? classes.active : classes.square}></div>
          <div className={indicator[1] ? classes.active : classes.square}></div>
          <div className={indicator[2] ? classes.active : classes.square}></div>
        </Grid>
      </Hidden>
      <Grid item xs={12} className={classes.desktopContainer} ref={refScroll}>
        {panels.map((panel, index) => {
          //updateActivation(index);
          return (
            <>
              <Hidden mdUp>
                <PanelMobile
                  key={index}
                  image={panel.image}
                  title={panel.title}
                  description={panel.description}
                  button={panel.button}
                  accordion={panel.accordion}
                />
              </Hidden>
              <Hidden smDown>
                <PanelDesktop
                  key={index}
                  index={index}
                  image={panel.image}
                  title={panel.title}
                  description={panel.description}
                  button={panel.button}
                  accordion={panel.accordion}
                />
              </Hidden>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
}
