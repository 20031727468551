import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import Hidden from "@material-ui/core/Hidden";
import generalStyle from "../../util/style";
import staticDataJson from "../../data/data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    // paddingLeft: 26,
  },
  pageTitle: {
    color: "#2d2926",
    marginTop: "2em",
    fontSize: 72,
    fontWeight: 400,
  },
  // content: {
  //   minHeight: 669,
  // },
  mediaItem: {
    marginTop: 8,
    paddingRight: "1em",
  },
  thumbnail: {
    overflow: "hidden",
    width: "100%",
    maxHeight: 288,
  },
  mediaTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.3,
    color:  "#2D2926",
    [theme.breakpoints.up("md")]: {
      fontSize: 27,
    },
    "&:hover": {
      textDecoration: "underline"
    }
  },

  link: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    textAlign: "right",
  },
  source: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    marginTop: 12,
    textOverflow: "ellipsis",
    whiteSpace: "break-spaces",
    overflow:"hidden",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      whiteSpace: "nowrap",
    },
  },
  date: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    textAlign: "right",
    lineHeight: 1.3,
    marginTop: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  space:{
    margin: "0 auto",
    [theme.breakpoints.down("md")]:{
      margin: 25
    }
  }
}));
//
// const mockData = [
//   {
//     title:
//         "The UNICEF CryptoFund. What is it all about?",
//     url: "https://unicef.org",
//     source: "unicef.org",
//     date: "12.12.2020",
//     img: media1,
//   },
//   {
//     title:
//         "UNICEF Innovation Fund 2020 for Blockchain Startups ($100K equity-free investment)",
//     url: "https://unicef.org",
//     source: "unicef.org",
//     date: "12.12.2020",
//     img: media2,
//   },
//   {
//     title:
//         "UNICEF Innovation Fund 2020 for Blockchain Startups ($100K equity-free investment)",
//     url: "https://unicef.org",
//     source: "unicef.org",
//     date: "12.12.2020",
//     img: media3,
//   },
// ];

// const mockData = {
//   title:
//       "UNICEF Innovation Fund 2020 for Blockchain Startups ($100K equity-free investment)",
//   url: "https://unicef.org",
//   source: "unicef.org",
//   date: "12.12.2020",
//   img: "https://www.unicef.org/innovation/sites/unicef.org.innovation/files/styles/large/public/UN0296098.jpg?itok=tqv_P9_G",
// };

export function MediaItem(props) {
  const classes = useStyles();
  return (
      <Grid item xs={12} md={4} className={classes.mediaItem}>
        <img src={require("../../data/"+props.image)} className={classes.thumbnail} />
        <Grid container className={classes.mediaContent}>
          <Grid item xs={12} className={classes.mediaTitle}>
            <a style={{textDecoration: "none", color: "#2D2926"}} href={props.link}>
              {props.title}
            </a>
          </Grid>

          <Grid item xs={6} className={classes.source}>
            {props.link}
          </Grid>
          <Grid item xs={6} className={classes.date}>
            {props.date}
          </Grid>
        </Grid>
      </Grid>
  );
}

export default function Media() {
  const classes = useStyles();
  const data = staticDataJson.media;
  const style = generalStyle();

  function navMedia() {
    window.location = '/media'
  }

  return (
      <Grid container className={classes.root}>
        <Container maxWidth={"lg"} style={{ padding: 0, marginTop: 126 }}>
          <Grid item xs={12} md={10} className={classes.space}>
            <h1 className={`${style.title}`}>CryptoFund in media</h1>
          </Grid>
          <Grid item xs={12} md={10} className={classes.space}>
            <Grid container spacing={3} className={classes.content}>
              <Hidden mdUp>
                {data.slice(0,2).map((item, index) => {
                  return <MediaItem {...item} key={index} />;
                })}
              </Hidden>
              <Hidden mdDown>
                {data.slice(0,3).map((item, index) => {
                  return <MediaItem {...item} key={index} />;
                })}
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} className={classes.space} style={{marginTop: 50}}>
            <FilledButton onClick={navMedia}>Browse media articles</FilledButton>
          </Grid>
        </Container>
      </Grid>
  );
}
