import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TransactionHorizontal from "./TransactionHorizontal.png";
import TransactionVertical from "./TransactionVertical.png";
import Hidden from "@material-ui/core/Hidden";
import FilledButton from "../Buttons/Filled";
import generalStyle from "../../util/style";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    paddingTop: 60,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: "#374EA2",
    paddingBottom: 60,
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingTop: 100,
      paddingBottom: 100,
      justifyContent: "center",
    },
  },
  subtitle: {
    lineHeight: 1.43,
    margin: 0,
    color: "#ffffff",
  },
  missionText: {
    lineHeight: 1.7,
    color: "#ffffff",
  },
  imageSpan: {
    minHeight: 120,
    display: "flex",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]:{
      marginTop: 80,
      marginBottom: 80
    }
  },
  tileTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 15,
    color: "#374EA2",
  },
  description: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 2,
    marginBottom: 60,
  },
  trackButton: {
    [theme.breakpoints.up("md")]:{
      marginTop: 10,
    }
  },
}));

export default function Transactions(props) {
  const classes = useStyles();
  const style = generalStyle();

  function navTrack() {
    window.location = '/track'
}

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg">
          <Grid item xs={12} md={8} style={{ margin: "0 auto" }}>
            <h3 className={`${classes.subtitle} ${style.title}`}>The transaction process</h3>
            <p className={`${classes.missionText} ${style.description}`}>
              UNICEF is able to receive cryptocurrency donations directly from a
              donor or via four official UNICEF fundraising entities, also known
              as National Committees (NatComs). Through these four National
              Committees - Australia, France, New Zealand, and the United States
              - donors are able to contribute to the CryptoFund in
              cryptocurrency.
            </p>
            <Hidden mdUp>
              <img src={TransactionVertical} className={classes.image} />
            </Hidden>
            <Hidden mdDown>
              <img src={TransactionHorizontal} className={classes.image} />
            </Hidden>
            <p className={`${classes.missionText} ${style.description}`}>
              To view the details of donations received and investments made, go
              to the Track Funds section.
            </p>

            <FilledButton primary={false} className={classes.trackButton} onClick={navTrack}>
              Track Funds
            </FilledButton>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
