import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import animoca from "./animoca.png";
import ethereumFoundation from "./ethereumFoundation.webp";
import Huobi from "./Huobi.png";
import generalStyle from "../../util/style";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    paddingTop: 140,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: "#ffffff",
  },
  subtitle: {
    margin: 0,
  },
  missionText: {
    marginBottom: 50,
  },
  imageSpan: {
    minHeight: 120,
    display: "flex",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    display: "flex",
    width: 210,
    paddingTop: 25,
    paddingBottom: 25,
  },
  tileTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 15,
    color: "#374EA2",
  },
  description: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 2,
    marginBottom: 60,
  },
  become: {
    paddingTop: 170,
  },
}));

export default function Mission(props) {
  const classes = useStyles();
  const style = generalStyle();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg" style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={12} md={10} style={{ margin: "0 auto" }}>
              <Grid item xs={12} md={8}>
                <h3 className={`${classes.subtitle} ${style.title}`}>Donors</h3>
                <p className={`${classes.missionText} ${style.description}`}>
                  Investing in open source technology to benefit children around
                  the world wouldn’t be possible without our generous donors. We
                  would like to thank our donors for contributing to the
                  CryptoFund. If you are interested in making a donation in
                  either bitcoin or ether, please, contact us.
                </p>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <img src={animoca} className={classes.image} />
                  <img src={ethereumFoundation} className={classes.image} />
                  <img src={Huobi} className={classes.image} />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item xs={12} md={3}>
                  <img src={animoca} className={classes.image} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <img src={ethereumFoundation} className={classes.image} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <img src={Huobi} className={classes.image} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
