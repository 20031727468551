import React, { Fragment } from "react";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Filters from "./Filters";
import Header from "./Header";
import FilledButton from "../../Buttons/Filled";
import {
  PrimaryOutlineButton,
  SecondaryOutlineButton,
} from "../../Buttons/Outline";
import AddIcon from "@material-ui/icons/Add";
import { PrimaryIconButton } from "../../Buttons/Icon";
import TableDetails from "./Details";
import {Container, createMuiTheme} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import staticDataJson from "../../../data/data";

const rows = staticDataJson.transactions;

const selectRow = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        "&$selected, &$selected:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    paddingTop: 35,
    width: 220,
    paddingLeft: 30,
  },
  recipient: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    verticalAlign: "top",
    paddingTop: 35,
    width: 230,
    paddingLeft: 30,
  },
  amount: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    paddingTop: 35,
    verticalAlign: "top",
    paddingLeft: 30,
  },
  type: {
    fontSize: 16,
    textAlign: "left",
    paddingTop: 28,
    verticalAlign: "top",
    paddingLeft: 30,
  },
  date: {
    fontSize: 16,
    textAlign: "left",
    paddingTop: 35,
    verticalAlign: "top",
    paddingLeft: 30,
  },
  time: {
    fontSize: 16,
    textAlign: "left",
    color: "#ABABAB",
    paddingTop: 35,
    verticalAlign: "top",
    paddingBottom: 5,
    paddingRight: 30,
    paddingLeft: 30,
  },
  showMore: {
    textAlign: "center",
    marginTop: 60,
    marginBottom: "8em",
  },
  showMoreBtn: {},
  row: {
    height: 131,
    ".MuiTableRow-root.Mui-selected": {
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();

  const table = new Array(9999).fill(false);
  const [order, setOrder] = React.useState("asc");
  const [filter, setFilter] = React.useState("");
  const [expended, setExpend] = React.useState(table);
  const [orderBy, setOrderBy] = React.useState("recipient");
  const [selected, setSelected] = React.useState([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleExpend = (index) => {
    expended.splice(index, 1, !expended[index]);
    setExpend(expended);
  };

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
      <Container maxWidth={"lg"} style={{ padding: 0 }}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Filters onFilterChange={handleFilterChange} />
            <TableContainer style={{ width: "100%" }}>
              <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
              >
                <Header
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .filter((row) => row.transactionType.includes(filter))
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.from);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <Fragment key={index}>
                              <MuiThemeProvider theme={selectRow}>
                                <TableRow
                                    onClick={(event) => handleClick(event, row.from)}
                                    key={index}
                                    selected={isItemSelected}
                                    className={classes.row}
                                >
                                  <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      className={classes.name}
                                      style={{ verticalAlign: "top" }}
                                  >
                                    {row.from}
                                  </TableCell>
                                  <TableCell
                                      align="right"
                                      className={classes.recipient}
                                  >
                                    {row.to}
                                  </TableCell>
                                  <TableCell align="right" className={classes.amount}>
                                    {row.amount} {row.currency}
                                  </TableCell>
                                  <TableCell align="right" className={classes.type}>
                                    {row.transactionType === "invested" ? (
                                        <SecondaryOutlineButton
                                            disableTouchRipple={true}
                                            disableFocusRipple={true}
                                            style={{
                                              borderRadius: 36,
                                              fontSize: 14,
                                              color: "#1CABE2",
                                              borderColor: "#1CABE2",
                                              cursor: "unset"
                                            }}
                                        >
                                          Investment made
                                        </SecondaryOutlineButton>
                                    ) : (
                                        <PrimaryOutlineButton
                                            disableTouchRipple={true}
                                            disableFocusRipple={true}
                                            style={{borderRadius: 36, fontSize: 14, cursor: "unset" }}
                                        >
                                          Donation received
                                        </PrimaryOutlineButton>
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.time}>
                                    <p style={{ margin: 0, marginBottom: 25 }}>
                                      {row.details[1]
                                          ? row.details[1].time
                                          : row.details[0].time}
                                    </p>
                                    <div
                                        style={{ marginBottom: 0, textAlign: "right" }}
                                    >
                                      <PrimaryIconButton
                                          style={{
                                            "&:hover": { textDecoration: "underline" },
                                            textTransform: "none",
                                            letterSpacing: 0,
                                            fontFamily: "'Prompt', sans-serif",
                                            fontSize: 16,
                                            fontWeight: "normal",
                                            padding: 0,
                                            lineHeight: "27px",
                                          }}
                                          endIcon={
                                            expended[index] ? (
                                                <RemoveIcon />
                                            ) : (
                                                <AddIcon />
                                            )
                                          }
                                          onClick={() => handleExpend(index)}
                                      >
                                        Transaction details
                                      </PrimaryIconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </MuiThemeProvider>
                              {expended[index] ? (
                                  <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, row.name)}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.name}
                                      selected={isItemSelected}
                                      className={classes.row}
                                  >
                                    <TableCell colSpan={6} style={{ padding: 0 }}>
                                      <TableDetails row={row.details} />
                                    </TableCell>
                                  </TableRow>
                              ) : (
                                  <></>
                              )}
                            </Fragment>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                    </TableRow>
                    )}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    </Paper>
                    <div className={classes.showMore}>
                  {rowsPerPage !== rows.length && (
                    <FilledButton
                    className={classes.showMoreBtn}
                    onClick={() => {
                    setRowsPerPage(Math.min(rowsPerPage + 5, rows.length));
                  }}
                    >
                    Show more transactions
                    </FilledButton>
                    )}
                    </div>
                    </div>
                    </Container>
                    );
                  }
