import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AppContext from "../../context/AppContext";


const useStyles = makeStyles((theme) => ({
  summary: {
    fontFamily: "'Prompt', sans-serif",
  },
  content: {
    padding: 0,
    textAlign: "left",
  },
}));

const primaryCardStyles = makeStyles((theme) => ({
  card: { backgroundColor: theme.palette.primary.main, color: "#ffffff" },
  bottom: {
    backgroundColor: theme.palette.primary.light,
    padding: 20,
    paddingLeft: 32,
    [theme.breakpoints.up("md")]: {
      padding: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  bottomSection: {
    backgroundColor: theme.palette.primary.light,
  },
}));
const secondaryCardStyles = makeStyles((theme) => ({
  card: { backgroundColor: theme.palette.secondary.main, color: "#ffffff" },
  bottom: {
    backgroundColor: theme.palette.secondary.light,
    paddingTop: 10,
    paddingLeft: 32,
    [theme.breakpoints.up("md")]: {
      padding: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  bottomSection: {
    backgroundColor: theme.palette.secondary.light,
  },
}));
const cardStyles = makeStyles((theme) => ({
  total: {
    fontSize: 70,
    paddingLeft: 28,
    paddingBottom: 24,
    paddingTop: 12,
    fontWeight: 200,
    [theme.breakpoints.up("md")]: {
      fontSize: 83,
      paddingLeft: 40,
      paddingBottom: 16,
      paddingTop: 20,
      fontWeight: 200,
    },
  },
  position: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 40,
    },
  },
  label: {
    fontSize: 26,
    fontWeight: 400,
    [theme.breakpoints.up("md")]: { fontSize: 36 },
  },
  currencyIcon: { textAlign: "right", fontSize: 32, paddingRight: 8 },
  currencyTotal: {
    marginTop: 10,
    fontSize: 26,
    lineHeight: 1,
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
    },
  },
  received: {
    fontSize: 18,
    [theme.breakpoints.up("lg")]: { fontSize: 20 },
    [theme.breakpoints.up("md")]: { fontSize: 12 },
  },
  rightSide: {
    [theme.breakpoints.up("md")]: {
      marginLeft: -30,
      width: 500,
    },
  },
}));

function Card({
  color = "primary",
  data = { total: 0, label: "", btc: 0, eth: 0 },
  type,
}) {
  const classes = cardStyles();
  let colorClasses;
  if (color === "primary") {
    colorClasses = primaryCardStyles();
  } else {
    colorClasses = secondaryCardStyles();
  }

  const { total, label, btc, eth } = data;

  return (
    <Grid container className={colorClasses.card}>
      <Grid item xs={12} className={classes.total}>
        <span>
          {total}
          <span className={classes.label}>&nbsp;{label}</span>
        </span>
      </Grid>

      <Grid container className={colorClasses.bottomSection}>
        <Grid item xs={12} md={6} className={colorClasses.bottom}>
          <Grid container className={classes.position}>
            <Grid item xs={12} className={classes.received}>
              Bitcoin {type}*
            </Grid>
            <Grid item xs={12} className={classes.currencyTotal}>
              {btc.toFixed(2)} BTC
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className={colorClasses.bottom}
          style={{ paddingBottom: 20 }}
        >
          <Grid container className={classes.rightSide}>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12} className={classes.received}>
                  Ether {type}*
                </Grid>
                <Grid item xs={12} className={classes.currencyTotal}>
                  {eth.toFixed(2)} ETH
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function Summary({ color, type, data, style }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.summary} style={{ ...style }}>
      <Grid item xs={12} className={classes.content}>
        <Card color={color} type={type} data={data} />
      </Grid>
    </Grid>
  );
}

const sectionStyles = makeStyles((theme) => ({
  summary: {
    paddingTop: "10em",
    paddingBottom: "14em",
  },
  primarySection: {
    [theme.breakpoints.up("md")]: {},
  },
  secondarySection: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 85,
      marginLeft: -40,
    },
  },
}));

export function SummarySection({ primaryData, secondaryData }) {
  const classes = sectionStyles();

  const { btcInvestments, ethInvestments, btcDonations, ethDonations } =
    useContext(AppContext);

  const donorData = {
    total: 4,   // TODO: compute this from transaction data, do not hardcode
    label: primaryData.label,
    btc: btcDonations || 0,
    eth: 2527 || 0
  };

  const investmentData = {
    total: 37,  // TODO: compute this from transaction data, do not hardcode
    label: secondaryData.label,
    btc: btcInvestments || 0,
    eth: ethInvestments || 0,
  };

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} md={10} style={{ margin: "0 auto" }}>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.primarySection}>
              <Summary color={"primary"} data={donorData} type={"received"} />
            </Grid>
            <Grid item xs={12} md={6} className={classes.secondarySection}>
              <Summary
                color={"secondary"}
                data={investmentData}
                type={"invested"}
              />
              <p
                style={{ color: "#374EA2", fontFamily: "Roboto", fontSize: 14 }}
              >
                * the crypto values have been rounded off to the closest whole
                number
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
