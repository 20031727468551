import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OpenSource from "./OpenSource.png";
import EquityFree from "./EquityFree.png";
import Impact from "./Impact.png";
import Investing from "./Investing.png";
import generalStyle from "../../util/style";

const tiles = [
  {
    image: OpenSource,
    title: "Open source",
    description: `The Innovation Fund invests exclusively in open source solutions. The
Fund generates value by building open source IP and scalable digital
public goods, strengthening communities of problem solvers, and
growing solutions that bring results for children and the world.`,
  },
  {
    image: EquityFree,
    title: "Equity free",
    description: `The CryptoFund seeks to create communities of global problem solvers. The community works on solutions that are open source intellectual property, called Digital Public Goods.`,
  },
  {
    image: Impact,
    title: "Impact",
    description: `The Fund monitors the progress of each investment in real-time. We measure progress on two different levels. First, aggregated metrics for all portfolio companies tracking sustainability of the business and the technology developed. Second, the social impact which is specific to the context and the type of solution that the startup is building.`,
  },
  {
    image: Investing,
    title: "Smart investing",
    description: `The UNICEF Innovation Fund is committed to building a diverse portfolio of startups with an ambitious target of 50% female-led or female-founded investments.`,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    paddingTop: 60,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#F2F4F6",
    justifyContent: "center",
  },
  subtitle: {
    lineHeight: 1.43,
    margin: 0,
  },
  missionText: {
    lineHeight: 1.7,
    marginBottom: 50,
  },
  imageSpan: {
    minHeight: 120,
    display: "flex",
    alignItems: "center",
  },
  image: {
    maxWidth: 120,
    maxHeight: 120,
    marginBottom: 10,
    marginLeft: 10,
  },
  tileTitle: {
    fontFamily: "'Prompt', sans-serif",
    marginBottom: 10,
    marginTop: 15,
    color: "#374EA2",
    fontWeight: "normal",
    fontSize: 22,
    [theme.breakpoints.up("md")]: {
      fontSize: 27,
    },
  },
  description: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "26px",
    marginBottom: 60,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      lineHeight: "28px",
    },
  },
  mission: {
    padding: 26,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      paddingRight: 290,
    },
  },
  spacing: {
    marginTop: 60,
    [theme.breakpoints.up("md")]: {
      marginTop: 80,
    },
  },
}));

export default function Mission(props) {
  const classes = useStyles();
  const style = generalStyle();
  function Tile(props) {
    return (
      <Grid item xs={12} md={6}>
        <div className={classes.imageSpan}>
          <img src={props.image} className={classes.image} />
        </div>
        <h4 className={classes.tileTitle}>{props.title}</h4>
        <p className={classes.description}>{props.description}</p>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid item xs={12} md={10} className={classes.mission}>
            <h3 className={`${classes.subtitle} ${style.title}`}>
              Our mission
            </h3>
            <p className={`${classes.missionText} ${style.description}`}>
              The CryptoFund is part of UNICEF’s larger Innovation Fund, which
              allows UNICEF to quickly assess, fund and grow open-source
              solutions that can improve children’s lives. Financial and
              technological support is available for companies that are using
              technology in innovative ways to improve the world. Our ambition
              is to build successful digital solutions into{" "}
              <a href="https://digitalpublicgoods.net." target="_blank" rel="noopener noreferrer">
                digital public goods
              </a>
              , to ensure fair, equitable, and open access to these unique and
              new tools for human development on a global scale.
            </p>

            <Grid container spacing={6} className={classes.spacing}>
              {tiles.map((tile) => {
                return (
                  <Tile
                    image={tile.image}
                    title={tile.title}
                    description={tile.description}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
