import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hero from "../Hero";
import Summary from "../Summary";
import ProjectList from "./ProjectList";
import AppContext from "../../context/AppContext";
import jsonData from "../../data/data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  hero: {
    marginBottom: 60,
  },
}));

export default function Track() {
  const classes = useStyles();

  const { btcInvestments, ethInvestments } =
    useContext(AppContext);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
  });

  const summaryData = {
    total: jsonData.projects.length,
    label: " investments",
    btc: btcInvestments || 0,
    eth: ethInvestments || 0,
  };
  const title = "Projects";
  const description =
    "The CryptoFund makes crypto-denominated investments in companies that are developing open-source software and data-driven solutions to address the most pressing challenges facing children and young people. Projects receiving investment must use the cryptocurrency in its native form. In addition to receiving crypto assets, projects receive mentorship on various topics to develop their solutions.";

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.hero}>
          <Hero
            title={title}
            description={description}
            buttons={null}
            contentRight={
              <Summary
                color={"secondary"}
                data={summaryData}
                type={"invested"}
                style={{ marginTop: "8em" }}
              />
            }
          />
          <ProjectList projects={jsonData.projects}/>
        </Grid>
      </Grid>
    </Container>
  );
}
