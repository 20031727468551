import { makeStyles } from "@material-ui/core/styles";
const generalStyle = makeStyles((theme) => ({
    title: {
        fontFamily: "'Prompt', sans-serif",
        fontSize: 26,
        lineHeight: 1.3,
        fontWeight: 400,
        [theme.breakpoints.up("md")]: {
            fontSize: 36,
            lineHeight: 1.22,
        }
    },
    description: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 18,
        lineHeight: "30px",
        [theme.breakpoints.up("md")]: {
            fontSize: 20,
            lineHeight: "34px",
        }
    }
}))

export default generalStyle;
