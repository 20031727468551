import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"Cabin", sans-serif',
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  pagination: {
    "& button": {
      height: 32,
      width: 7,
      padding: 0,
      color: "#000000",
      fontSize: 18,
      letterSpacing: 1.17,
      fontWeight: 400,
      "&.Mui-selected": {
        borderRadius: 0,
        color: theme.palette.secondary.main,
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
  },
  index: {
    fontFamily: '"Roboto", sans-serif',
    color: "#ababab",
    fontSize: 18,
    lineHeight: 1.5,
  },
}));

export default function PagePagination({
  start,
  end,
  totalItems,
  totalPages,
  currentPage,
  onClick,
  currentItem,
  itemName,
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Pagination
        count={totalPages}
        shape="rounded"
        className={classes.pagination}
        page={currentPage}
        onChange={(e, value) => {
          if (onClick) onClick(value);
        }}
      />

      <div className={classes.index}>
        {currentItem > totalItems ? totalItems : currentItem} of {totalItems} {itemName}
      </div>
    </Grid>
  );
}
