import React, {useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppContext from "../../context/AppContext";
import Hidden from "@material-ui/core/Hidden";
import {Link} from "react-router-dom";
import unicefLogo from "./unicef_logo.svg"
import MenuPopper from "../MenuPopper";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {usdFormatter} from "../../util";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "absolute",
    top:0,
    backgroundColor: "#1cabe2",
    zIndex: 9999,
  },
  toolbar: {
    minHeight: "unset",
    padding: 0,
  },
  brandImage: {},
  hamburger: {
    position: "absolute",
    right: 0,
    color: "#000000",
  },
  menu: {

    width: "100%",
    height: "70px",
  },
  menuIcon: {
    position: "absolute",
    fontSize: 36,
    right: 14,
    fontWeight: 200,
  },
  brand: {
    paddingTop: 19
  },
  item: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    color: "white",
    textDecoration: "none",
    minHeight: 70,
    borderBottom: "solid 3px rgba(0,0,0,0)",
    "&:hover": {
      color: "white",
      textDecoration: "underline"
    },
  },

  fullList: {
    display: "flex",
    flexDirection: "column",

    width: "auto",
    height: "100%",
  },
  close: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 19,
    paddingTop: 19,
    paddingLeft: 24,
    height: 70,
  },
  path: {
    textDecoration: "none"
  },
  dailyPrice: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    minHeight: 70,
    textDecoration: "none",
    color: "white",
    "&:hover": {
      color: "white",
      textDecoration: "underline"
    },
  },
  menuPopper: {
    width: 400,
    color: "#000000",
    backgroundColor: "#F2F4F6",
    padding: 32,
    fontSize: 20,
    lineHeight: 2.1,
    zIndex: 9999,
  },
  closeIcon: {
    position: "absolute",
    right: 14,
    fontSize: 36,
    fontWeight: 200,
  },
  brandLogo: {
    display: "inline",
    marginRight: "4em",
  },
  mobileItem: {
    paddingLeft: 24,
  },
  activeMobileItem: {
    paddingLeft: 24,
    backgroundColor: theme.palette.primary.main,
  },
  activeMobileItemText: {
    fontSize: 20,
    lineHeight: 2.1,
    fontWeight: 500,
    color: "#ffffff",
  },
  mobileItemText: {
    fontSize: 20,
    lineHeight: 2.1,
    fontWeight: 500,
    color: "#000000",
  },
  socialLinks: {
    paddingTop: 60,
    paddingLeft: 9,
  },
  icon: {
    fontSize: 28,
    margin: ".5em",
    color: theme.palette.primary.main,
  },
  arrowUp: {
  width: 0,
  height: 0,
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderBottom: "5px solid black",
}
}));

function DesktopNav(props) {
  const classes = useStyles();
  const { ethRate, btcRate } = useContext(AppContext);
  return (
      <Hidden mdDown>
        <Container maxWidth={"lg"} style={{backgroundColor: "#1cabe2", height: "70px", paddingRight: 46}}>
          <Grid container direction="row-reverse">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.unicef.org/"
                style={{ textDecoration: "none" }}
            >
              <Grid
                  item
                  className={classes.dailyPrice}
              >
                Visit UNICEF Global
              </Grid>
            </a>
            <Link to="#" style={{ textDecoration: "none" , height: 70}}>
              <MenuPopper
                  arrowUp={true}
                placement={"bottom"}
                button={
                  <Grid item className={classes.dailyPrice}>
                    <MonetizationOnIcon style={{ marginRight: 10 }} />
                    Daily crypto price
                  </Grid>
                }
              >
                <Grid container className={classes.menuPopper}>
                  <Grid item xs={12}>
                    1 bitcoin (BTC) = {usdFormatter(btcRate || 0)} USD
                  </Grid>
                  <Grid item xs={12}>
                    1 ether (ETH) = {usdFormatter(ethRate || 0)} USD
                  </Grid>
                  <Grid item xs={12} style={{fontFamily: "Roboto", fontSize: 14}}>
                    <small>Prices calculated at 5:01 UTC</small>
                  </Grid>
                </Grid>
              </MenuPopper>
            </Link>
            <Grid item style={{ flexGrow: 1 }} className={classes.brand}>
              <img src={unicefLogo}/>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
  );
}

function MobileNav() {
  const classes = useStyles();
  return (
      <Hidden lgUp>
        <Grid container className={classes.menu}>
          <Grid item xs={12} className={classes.close}>
            <img src={unicefLogo}/>
          </Grid>
        </Grid>
      </Hidden>
  );
}

export default function TopBar(props) {
  const classes = useStyles();

  return (
    <AppBar position={"absolute"} elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <MobileNav />
        <DesktopNav />
      </Toolbar>
    </AppBar>
  );
}
