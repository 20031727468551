import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import OutlineButton from "../Buttons/Outline";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import staticDataJson from "../../data/data";

const useStyles = makeStyles((theme) => ({
  hero: {
    marginTop: "2em",
    marginBottom: 120,
    [theme.breakpoints.up("md")]: {
      marginTop: 70,
    },
  },
  heroBox: {
    padding: 28,
    background: "#1CABE2",
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    lineHeight: 1.3,
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      padding: "22px 21px 22px 25px",
      fontSize: 18,
      lineHeight: 1.22,
      marginTop: 102,
      width: 360,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "55px 42px 55px 50px",
      fontSize: 36,
      lineHeight: 1.22,
      marginTop: 102,
      width: 520,
    },
  },
  smallText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.66,

    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.66,
    },
  },
  heroButtons: {
    marginTop: 30,
  },
  button: {
    marginTop: 15,
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      display: "inline",
      marginRight: 20,
    },
  },
  mobileCarousel: {
    maxWidth: "100%",
  },
  desktopCarousel: {
    width: "100%",
    marginLeft: "-200px",
    marginTop: 15,
  },
  navigation: {
    display: "flex",
    alignItems: "center",
  },
  navBox: {
    background: "#1CABE2",
    height: 50,
    width: 30,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#fff",
  },
  square: {
    display: "inline-block",
    height: 9,
    width: 9,
    background: "#CED1D5",
    marginRight: 15,
  },
  active: {
    transition: "background-color .3s",
    backgroundColor: "#374EA2",
    height: 9,
    width: 9,
    marginRight: 15,
    display: "inline-block",
  },
  description: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 18,
    lineHeight: 1.55,
    color: "#374EA2",
  },
  descriptionDesktop: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 15,
    lineHeight: 1.55,
    color: "#374EA2",
    marginTop: 0,
    transitionTimingFunction: "ease-in-out",
    transitionDuration: ".3s",
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
    },
  },
  squareGroup: {
    paddingTop: 5,
    width: 80,
  },
  effect: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    zIndex: 1,
    // whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  outline: {
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      width: 163.4,
      color: "#fff",
    },
  },
  heroImage: {
    width: 746,
    height: 482,
    [theme.breakpoints.up("lg")]: {
      width: 1063,
      height: 687,
    },
  },
}));

const carouselItems = staticDataJson.media;

function MobileCarousel() {
  const classes = useStyles();
  let index = 0;
  const [indicator, setIndicator] = useState([true, false, false]);
  const [text, setText] = useState(staticDataJson.media[0].title);
  const [image, setImage] = useState(staticDataJson.media[0].image);
  const [link, setLink] = useState(staticDataJson.media[0].link + "");
  const updateActivation = (index) => {
    let t = [false, false, false];
    t[index] = true;
    setIndicator(t);
  };
  useEffect(() => {
    setInterval(() => {
      index = (index + 1) % 3;
      updateActivation(index % carouselItems.length); //number of squares must be updated
      setText(carouselItems[index % carouselItems.length].title);
      setImage(carouselItems[index % carouselItems.length].image);
      setLink(carouselItems[index % carouselItems.length].link);
    }, 5000);
  }, []);
  return (
    <Hidden mdUp>
      <div>
        <Grid
          container
          style={{ position: "relative" }}
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <Grid item>
            <img src={image} className={classes.mobileCarousel} />
          </Grid>
          <Grid container style={{ position: "absolute", height: "100%" }}>
            <Grid item xs={6} className={classes.navigation}>
              <span className={classes.navBox}>
                <KeyboardArrowLeftIcon />
              </span>
            </Grid>
            <Grid item xs={6} className={classes.navigation}>
              <span
                className={classes.navBox}
                style={{ position: "absolute", right: 0 }}
              >
                <KeyboardArrowRightIcon />
              </span>
            </Grid>
          </Grid>
        </Grid>

        <Container maxWidth={"lg"}>
          <Grid container>
            <Grid item xs={12}>
              <div
                className={indicator[0] ? classes.active : classes.square}
              ></div>
              <div
                className={indicator[1] ? classes.active : classes.square}
              ></div>
              <div
                className={indicator[2] ? classes.active : classes.square}
              ></div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.description}>{text}</p>
          </Grid>
        </Container>
      </div>
    </Hidden>
  );
}

function DesktopCarousel() {
  const classes = useStyles();
  let index = 0;
  const [indicator, setIndicator] = useState([true, false, false]);
  const [text, setText] = useState(staticDataJson.media[0].title);
  const [image, setImage] = useState(staticDataJson.media[0].image);
  const [link, setLink] = useState(staticDataJson.media[0].link + "");
  const updateActivation = (index) => {
    let t = [false, false, false];
    t[index] = true;
    setIndicator(t);
  };
  useEffect(() => {
    setInterval(() => {
      index = (index + 1) % 3;
      updateActivation(index % carouselItems.length); //number of squares must be updated
      setText(carouselItems[index % carouselItems.length].title);
      setImage(carouselItems[index % carouselItems.length].image);
      setLink(carouselItems[index % carouselItems.length].link);
    }, 5000);
  }, []);
  return (
    <Hidden smDown>
      <img src={image} className={classes.heroImage} />
      <Grid container style={{ marginTop: 15 }}>
        <Grid item className={classes.squareGroup}>
          <div className={indicator[0] ? classes.active : classes.square}></div>
          <div className={indicator[1] ? classes.active : classes.square}></div>
          <div className={indicator[2] ? classes.active : classes.square}></div>
        </Grid>
        <Grid
          item
          md
          className={classes.effect}
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <p className={`${classes.descriptionDesktop}`}>{text}</p>
        </Grid>
      </Grid>
    </Hidden>
  );
}

export default function Hero() {
  const classes = useStyles();

  function navProjects() {
    window.location = "/projects";
  }

  function navFunding() {
    window.location = "/funding";
  }

  return (
    <Fragment>
      <Container maxWidth={"lg"} className={classes.hero}>
        <Grid container>
          <Grid item xs={12} md={5} style={{ zIndex: 1 }}>
            <div className={classes.heroBox}>
              The UNICEF CryptoFund makes fast, transparent investments in
              bitcoin or ether.
              <Hidden smDown>
                <p className={classes.smallText}>
                  The CryptoFund and the UNICEF Innovation Fund together provide
                  fiat and cryptocurrency funding to early-stage startups.
                </p>
              </Hidden>
              <div className={classes.heroButtons}>
                <div className={classes.button}>
                  <FilledButton onClick={navProjects}>
                    See all investments
                  </FilledButton>
                </div>
                <div className={classes.button}>
                  <OutlineButton
                    className={classes.outline}
                    onClick={navFunding}
                  >
                    Get funding
                  </OutlineButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={8} className={classes.desktopCarousel}>
            <DesktopCarousel />
          </Grid>
        </Grid>
      </Container>
      <MobileCarousel />
    </Fragment>
  );
}
