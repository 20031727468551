import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hero from "../Hero";
import AboutHero from "./About.png";
import MissionSection from "./Mission";
import TransactionSection from "./Transactions";
import Donors from "./Donors";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
  },
  hero: {
    marginBottom: 100,
  },
}));

export default function About() {
  const classes = useStyles();
  const title = "About";
  const description =
    "The CryptoFund is a new financial vehicle allowing UNICEF to receive, hold, and disburse cryptocurrency - a first for the UN. This cryptocurrency-denominated fund is a part of UNICEF’s Innovation Fund. The investments are made in bitcoin and ether.";

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
  });

  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.hero}>
            <Hero title={title} description={description} image={AboutHero} />
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.root}>
        <MissionSection />
        <TransactionSection />
      </Grid>
      <Donors />
    </>
  );
}
