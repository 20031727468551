import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import apply from "./Apply.png";
import Hidden from "@material-ui/core/Hidden";
import FilledButton from "../Buttons/Filled";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    paddingTop: 60,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: "#374EA2",
    paddingBottom: 60,
    [theme.breakpoints.up("md")]: {
      paddingTop: 200,
      paddingBottom: 200,
    },
  },
  subtitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    fontWeight: 400,
    lineHeight: 1.43,
    margin: 0,
    marginTop: 30,
    marginBottom: 15,
    color: "#ffffff",
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  missionText: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.7,
    marginBottom: 50,
    color: "#ffffff",
  },
  imageSpan: {
    minHeight: 120,

    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
  },
  tileTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 15,
    color: "#374EA2",
  },
  description: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.7,
    margin: 0,
    color: "#ffffff",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  trackButton: {},
  spacing: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 200,
      paddingTop: 70,
    },
  },
}));

export default function How(props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={7} className={classes.spacing}>
              <img src={apply} className={classes.image} />
            </Grid>
            <Grid item xs={12} md={5}>
              <p className={classes.subtitle}>
                Learn more about the Innovation Fund
              </p>
              <p className={classes.description}>
                The applications go through the selection process defined by the
                Innovation Fund (IF).
              </p>
              <p className={classes.description}>
                The IF team engages various internal stakeholders to determine
                which startups can benefit the most using cryptocurrency. The
                selected startups receive funding up to USD 100K in
                cryptocurrency.
              </p>
              <Hidden mdDown>
                <a
                  href="https://www.unicefinnovationfund.org/"
                  target="_blank"
                  rel="noopener noreferrer" 
                  style={{ textDecoration: "none" }}
                >
                  <FilledButton primary={false} style={{ marginTop: 50 }}>
                    Learn more about Innovation Fund
                  </FilledButton>
                </a>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
