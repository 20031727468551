import React, { Fragment } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import generalStyle from "../../util/style";
import unicefLogo from "./unicef_logo.svg";
import mail from "./mail.svg";
import twitter from "./twitter.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    paddingBottom: 300,
    [theme.breakpoints.up("md")]: {
      // paddingLeft: 200,
      // paddingRight: 270,
      // paddingBottom: 228,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.33,
    marginTop: 58,
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  social: {
    marginTop: 50,
    fontSize: 24,
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  links: {
    fontSize: 18,
    color: "#ffffff",
  },
  ratesTitle: {
    marginTop: 20,
    marginBottom: 6,
    fontFamily: "'Roboto', sans-serif",
    fontSize: 18,
    lineHeight: 1.615,
  },
  rateItem: {
    display: "flex",
    marginTop:10,
    textAlign: "right",
    fontFamily: "'Roboto', sans-serif",
    fontSize: 18,
    lineHeight: 1.615,
  },
  rateIcon: {
    marginRight: 13,
    fontFamily: "'Roboto', sans-serif",
    fontSize: 18,
    lineHeight: 1.615,
  },

  description: {
    marginTop: 12,
  },
  item: {
    listStyleType: "none",
    marginTop: 14,
    marginBottom: 21,
    display: "block",
    textDecoration: 'none',
    color: "#ffffff"
  },
  copyright: {
    fontSize: 10,
    color: "#c3c3c3",
    textAlign: "center",
    marginTop: 50,
    marginBottom: 55,
  },
  icon: {
    color: "#ffffff",
    fontSize: 28,
    margin: ".5em",
    cursor: "pointer",
    "& :hover": {
      color: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: "1em",
    },
  },
  primary: {
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      margin: 0
    },
    fontWeight: 600,
    color: "white",
  },
  brand: {
    marginTop: 51
  }
}));

export default function Footer() {
  const classes = useStyles();

  function Title() {
    return (
      <Grid item xs={12} md={10}>
        <Grid item className={classes.brand}>
          <img src={unicefLogo}/>
        </Grid>
        <h3 className={classes.primary}>
          CryptoFund
        </h3>
      </Grid>
    );
  }

  function Rates() {
    return (
      <Grid
        item
        xs={12}
        md={2}
        className={classes.links}
        style={{ marginBottom: 12}}
      >
        {/* <p className={classes.ratesTitle}>Cryptocurrency rates</p>
        <Grid item container style={{textAlign: "right"}}>
          <Grid item xs={12} md={12} className={classes.rateItem}>
            1 BTC <span style={{ marginLeft: 6 }}>$51,123 USD</span>
          </Grid>
          <Grid item xs={12} md={12} className={classes.rateItem}>
            1 ETH
            <span style={{ marginLeft: 6, fontWeight: 300 }}>$2,500 USD</span>
          </Grid>
        </Grid> */}
        {/* <hr style={{border: "1px solid #FFFFFF", backgroundColor: "white", width: 20, marginLeft: 0, marginTop: 20}}/> */}
        <Grid container>
          <a
            href='https://www.unicef.org/legal'
            className={classes.item}
            target='_blank'
            rel="noopener noreferrer">
            Legal
          </a>
          <p style={{fontFamily: "Roboto", fontSize: 14, marginTop: 0, padding: 0}}>Find legal information and policies related to UNICEF's digital communications</p>
        </Grid>
      </Grid>
    );
  }

  function Links() {
    return (
      <Fragment>
        <Hidden mdDown>
          <Grid item md={2} className={classes.links}>
          <a href='/home' className={classes.item}>Home</a>
            <a href='/about' className={classes.item}>About</a>
            <a href='/funding' className={classes.item}>Get funding</a>
            <a href='/projects' className={classes.item}>Projects</a>
            <a href='/track' className={classes.item}>Track funds</a>
            <a href='/media' className={classes.item}>Media</a>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={6} md={8} className={classes.links}>
            <p className={classes.item}>Home</p>
            <p className={classes.item}>About</p>
            <p className={classes.item}>Get funding</p>
          </Grid>
          <Grid item xs={6} md={2} className={classes.links}>
            <p className={classes.item}>Projects</p>
            <p className={classes.item}>Track funds</p>
            <p className={classes.item}>Media</p>
          </Grid>
        </Hidden>
      </Fragment>
    );
  }

  function Description() {
    const style = generalStyle();
    return (
      <Grid item xs={12} md={8} className={classes.links} style={{paddingRight: 100}}>
        <p className={`${classes.description} ${style.description}`} style={{ marginBottom: 0 }}>
          The UNICEF CryptoFund is a new financial vehicle allowing UNICEF to receive, hold, and
          disburse cryptocurrency - a first for the United Nations.The CryptoFund is a pooled fund
          of bitcoin and ether.It is a part of UNICEF's Venture Fund, with the distinction that
          investments made through the CryptoFund are denominated in crypto. The Fund supports
          start-ups, provides technical assistance to pilot, and accelerate innovative solutions
          that can bring results for children.​
        </p>
      </Grid>
    );
  }

  function Social() {
    return (
      <Grid item xs={12} md={2} className={classes.social}>
        <a href="mailto:blockchain@unicef.org"><img src={mail} className={classes.icon} style={{ marginLeft: 0 }} /></a>
        <a href="https://twitter.com/unicefinnovate" target="_blank" rel="noopener noreferrer"><img src={twitter} className={classes.icon} /></a>
      </Grid>
    );
  }

  // function Copyright() {
  //   return (
  //     <Grid item xs={12} className={classes.copyright}>
  //       Cryptofund 2021
  //     </Grid>
  //   );
  // }

  // function DesktopFooter() {
  //   return (
  //     <Grid container>
  //       <Title />
  //       <Social />
  //       <Links />
  //       <Description />
  //       <Rates />
  //     </Grid>
  //   );
  // }

  // function MobileFooter() {
  //   return (
  //     <Grid container>
  //       <Title />
  //       <Social />
  //       <Links />
  //       <Description />
  //       <Rates />
  //     </Grid>
  //   );
  // }

  return (
    <footer id="footer" className={classes.root}>
      <Container maxWidth={"lg"} style={{padding: 0}}>
        <Grid container style={{paddingLeft: 20}}>
            <Title />
            <Social />
            <Links />
            <Description />
            <Rates />
        </Grid>
      </Container>
    </footer>
  );
}
