import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const btnStyles = {
  fontFamily: '"Prompt", sans-serif',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.87,
  border: "solid 2px",
  borderRadius: 0,
  textTransform: "unset",
  padding: "10px 15px 10px 15px",
  minWidth: 132,
  height: 46,
  "&:hover": {
    background: "rgba(255, 255, 255, 0.1)",
  },
};

const OutlineBtn = withStyles((theme) => ({
  root: {
    ...btnStyles,
  },
}))(Button);

const PrimaryBtn = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    ...btnStyles,
  },
}))(Button);

const SecondaryBtn = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    ...btnStyles,
  },
}))(Button);

export default function OutlineButton(props) {
  return <OutlineBtn {...props}>{props.children}</OutlineBtn>;
}

export function PrimaryOutlineButton(props) {
  return <PrimaryBtn {...props}>{props.children}</PrimaryBtn>;
}

export function SecondaryOutlineButton(props) {
  return <SecondaryBtn {...props}>{props.children}</SecondaryBtn>;
}
