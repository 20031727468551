import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  primary: {
    fontWeight: 600,
    color: "white",
  },
  secondary: {
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
}));

export default function BrandLogo(props) {
  const classes = useStyles();
  return (
    <div className={props.className}>
      <span className={classes.root}>
        {/*<span className={classes.secondary}>UNICEF</span>{" "}*/}
        <span className={classes.primary}>CryptoFund</span>
      </span>
    </div>
  );
}
