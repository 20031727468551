import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import SearchIcon from "@material-ui/icons/Search";
// import IconButton from "@material-ui/core/IconButton";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import FilledButton from "../Buttons/Filled";
import transaction from "./transaction.png";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      width: 1070,
    },
  },
  top: {
    [theme.breakpoints.up("md")]: {
      padding: 50,
    },
  },
  bottom: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 16,
    background: "#EAEDF0",
    [theme.breakpoints.up("md")]: {
      padding: 20,
      paddingBottom: 40,
    },
  },
  image: { width: 485 },
  subtitle: {
    fontSize: 16,
    color: "#374EA2",
  },
  sender: {
    fontSize: 20,
    color: "#374EA2",
  },
  recipient: {
    fontSize: 20,
    fontWeight: 600,
    color: "#374EA2",
  },
  header: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    lineHeight: 2,
  },
  blueBox: {
    width: 220,
    background: "#1CABE2",
    padding: 20,
    color: "#fff",
  },
  sent: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
  amount: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 36,
    fontWeight: 300,
  },
  row: {
    marginTop: 9,
    marginBottom: 9,
  },
}));

export default function ProjectDetails({ data }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.top}>
        {data?.map((txDetail, index) => {
          return (
            <Grid container>
              <Grid item xs={12} md={7}>
                <img src={transaction} className={classes.image} />
                <Grid item container>
                  <Grid item md={9}>
                    <span className={classes.subtitle}>Sender</span>
                  </Grid>
                  <Grid item md={3}>
                    <span className={classes.subtitle}>Recipient</span>
                  </Grid>
                  <Grid item md={9}>
                    <span className={classes.sender}>{txDetail?.from}</span>
                  </Grid>
                  <Grid item md={3}>
                    <span className={classes.recipient}>
                      {txDetail?.recipient}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <div className={classes.blueBox}>
                  <p className={classes.sent}>Crypto Sent</p>
                  <span className={classes.amount}>{txDetail?.amount}</span>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} className={classes.bottom}>
        <Grid container className={classes.row}>
          <Grid item xs={12} md className={classes.header}>
            From
          </Grid>
          <Grid item xs={12} md className={classes.header}>
            Recipient
          </Grid>
          <Grid item xs={12} md className={classes.header}>
            Crypto amount
          </Grid>
          <Grid
            item
            xs={12}
            md
            className={classes.header}
            style={{ position: "relative" }}
          >
            Transaction proof{" "}
            <HelpOutlineIcon
              style={{
                top: 3,
                position: "absolute",
                marginLeft: 3,
                color: "#374EA2",
              }}
            />
          </Grid>
          <Grid item xs={12} md className={classes.header}>
            Time
          </Grid>
        </Grid>
        {data?.map((txDetail, index) => {
          return (
            <Grid container className={classes.row}>
              <Grid item xs={12} md>
                {txDetail.from}
              </Grid>
              <Grid item xs={12} md>
                {txDetail.recipient}
              </Grid>
              <Grid item xs={12} md>
                <b style={{ fontWeight: 600 }}>
                  {txDetail.amount} {txDetail.currency}
                </b>
              </Grid>
              <Grid item xs={12} md>
                <a href={txDetail.link} style={{ color: "#374EA2" }} target="_blank" rel="noopener noreferrer">
                  Link
                </a>
              </Grid>
              <Grid item xs={12} md style={{ color: "#8E8E8E" }}>
                {txDetail.time}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
