import React, { useState, Fragment, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import projectImage from "./projectImage.png";
import FilledButton from "../Buttons/Filled";
import { PrimaryOutlineButton } from "../Buttons/Outline";
import Modal from "../Modal";
import ProjectDetails from "./ProjectDetails";
import AppContext from "../../context/AppContext";

// const legendData = [
//   { color: "#1CABE2", label: "Employee salaries" },
//   { color: "#374EA2", label: "Vendors and merchants" },
//   { color: "#4FCFFF", label: "Network costs" },
//   { color: "#5E71B5", label: "Incentives" },
//   { color: "#A32DB3", label: "Others" },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 120,
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  searchWrapper: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginRight: 28,
    },
  },
  search: {
    fontFamily: "'Roboto', sans-serif",
    width: "100%",
    fontSize: 18,
    color: "#5E71B5",
    fontWeight: 500,
    borderRadius: 0,
    padding: 24,
    paddingLeft: 52,
    border: "solid 2px #A5A7AC",
    outline: "none",
    [theme.breakpoints.down("md")]:{
      fontSize: 14,
    },
    "&:hover": {
      borderRadius: 0,
      border: "solid 2px #A5A7AC",
      outline: "none",
    },
    "&:focus": {
      borderRadius: 0,
      border: "solid 2px #A5A7AC",
      outline: "none",
    },
    "&::placeholder": {
      color: "#5E71B5",
    },
  },
  searchIcon: {
    position: "absolute",
    top: 17,
    left: 17,
    fontSize: 30,
    color: "#374EA2",
  },
  sortBy: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    color: "#374ea2",
    fontWeight: 500,
    float: "right",
    paddingRight: 0,
    marginTop: "1em",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      marginTop: -55,
      marginRight: 28,
    },
  },
  legend: {
    paddingBottom: 40,
  },
  legendTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  legendLabel: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    color: "#2D2926",
  },
  listItemImage: {
    maxWidth: "100%",
    width: "100%",
  },
  details: {
    backgroundColor: "#F2F4F6",
    padding: 25,
    minHeight: 350,
    [theme.breakpoints.up("md")]: {
      marginTop: "4em",
      marginBottom: "-2em",
      marginLeft: "-2em",
      position: "relative"
    },
  },
  detailsTitle: {
    fontSize: 20,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 27,
      lineHeight: 1.13,
    },
  },
  subtitle: {
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    marginTop: 20,
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      marginTop: 30,
    },
  },
  detail: {
    margin: 0,
    marginTop: 4,
    fontSize: 18,
    fontFamily: "'Prompt', sans-serif",
    [theme.breakpoints.up("md")]: { fontSize: 20 },
  },
  allocations: {
    marginTop: 11,
  },
  buttons: {
    marginTop: 70,
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 10
    },
  },
  projectItem: {
    marginTop: 65,
    [theme.breakpoints.up("md")]: {
      marginTop: 105,
    },
  },
  showMore: {
    textAlign: "center",
    paddingTop: "12em",
  },
  expand: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  spacing: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 10,
      // position: "absolute"
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      width: 178.73,
    },
  },
  txDetailsBtn: {
    [theme.breakpoints.only("md")]: {
      float: "right",
    },
  },
  imageCredit: {
    fontSize: 12,
    color: "grey"
  }
}));

function ListItem({
  image = projectImage,
  imageCredit,
  title,
  amount,
  projectLink,
  currency,
  investmentName,
  country,
  // allocation,
  openModal,
  header,
}) {
  const classes = useStyles();

  function showTransactionDetails(data) {
    openModal(data);
  }

  // function AllocationBlock({ color, width }) {
  //   return (
  //     <div
  //       style={{
  //         width: width,
  //         height: 15,
  //         backgroundColor: color,
  //         display: "inline-block",
  //       }}
  //     ></div>
  //   );
  // }

  return (
    <Grid container className={classes.projectItem}>
      <Grid item xs={12} md={6}>
        <img src={image} className={classes.listItemImage} />
        { imageCredit ? <div className={classes.imageCredit}>{imageCredit}</div> : null }
      </Grid>
      <Grid item xs={12} md={6} className={classes.details}>
        <p className={classes.detailsTitle}>{header}</p>
        <Grid container>
          <Grid item xs={12} md>
            <p className={classes.subtitle}>Crypto invested</p>
            <p className={classes.detail}>
              {amount} {currency}
            </p>
          </Grid>
          <Grid item xs={12} md>
            <p className={classes.subtitle}>Startup name</p>
            <p className={classes.detail}>{investmentName}</p>
          </Grid>
          <Grid item xs={12} md>
            <p className={classes.subtitle}>Country</p>
            <p className={classes.detail}>{country}</p>
          </Grid>
          {/*<Grid item xs={12} md={12}>*/}
          {/*  <p className={classes.subtitle}>Fund allocation</p>*/}
          {/*  <div className={classes.allocations}>*/}
          {/*    {allocation.map((alloc, index) => {*/}
          {/*      return (*/}
          {/*        <AllocationBlock*/}
          {/*          key={index}*/}
          {/*          color={alloc.color}*/}
          {/*          width={alloc.amount * 2.25}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</Grid>*/}
        </Grid>
        <Grid container className={classes.buttons}>
          <Grid item xs={12} md={4} className={classes.spacing}>
            <FilledButton
                className={classes.button}
                onClick={() => {
                  window.open(projectLink, "_blank");
                }}
            >
              Read More
            </FilledButton>
          </Grid>
          <Grid item xs={12} md={7} style={{ clear: "both" }}>
            <PrimaryOutlineButton
                onClick={showTransactionDetails}
                className={`${classes.txDetailsBtn} ${classes.button}`}
            >
              Transaction Details
            </PrimaryOutlineButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// function LegendItem({ color, label }) {
//   const classes = useStyles();
//   return (
//     <Grid item xs={12} md style={{ paddingTop: 8, paddingBottom: 8 }}>
//       <div
//         style={{
//           display: "flex",
//           clear: "both",
//         }}
//       >
//         <div
//           style={{
//             marginTop: 3,
//             width: 48,
//             height: 15,
//             backgroundColor: color,
//             display: "inline-block",
//             marginRight: 10,
//           }}
//         ></div>
//         <span className={classes.legendLabel}>{label}</span>
//       </div>
//     </Grid>
//   );
// }

export default function ProjectList({ projects }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const { staticData } = useContext(AppContext);
  
  function openModal(index) {
    setProjectData(staticData.projects[index].transactionProof);
    setShowModal(true);
  }

  function closeModal() {
    setProjectData(null);
    setShowModal(false);
  }

  // staticData.projects.forEach((acct) => {
  //   acct.allocation = [
  //     { color: "#1CABE2", label: "Employee salaries", amount: 30 },
  //     { color: "#374EA2", label: "Vendors and merchants", amount: 20 },
  //     { color: "#4FCFFF", label: "Network costs", amount: 10 },
  //     { color: "#5E71B5", label: "Incentives", amount: 40 },
  //     { color: "#A32DB3", label: "Others", amount: 10 },
  //   ];
  // });

  return (
    <Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={11}>
          <div className={classes.searchWrapper}>
            <input
              type="text"
              className={classes.search}
              placeholder="Search for a project or startup name"
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
              value={searchFilter}
            />
            <SearchIcon className={classes.searchIcon} />
          </div>
          
        </Grid>
        {/*<Grid item xs={12} md={10} className={classes.legend}>*/}
        {/*  <p className={classes.legendTitle}>Fund allocations by startups</p>*/}
        {/*  <Grid container>*/}
        {/*    {legendData.map((item) => {*/}
        {/*      return <LegendItem label={item.label} color={item.color} />;*/}
        {/*    })}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={11}>
          {projects.filter((project) => {
              if (searchFilter === "") {
                return project;
              } else {
                return project.header.indexOf(searchFilter) >= 0;
              }
            })
            .map((project, index) => {
              return (
                <ListItem
                  key={index}
                  {...project}
                  openModal={() => openModal(index)}
                />
              );
            })}
        </Grid>

      </Grid>
      <Modal open={showModal} handleClose={closeModal}>
        <ProjectDetails data={projectData} />
      </Modal>
    </Fragment>
  );
}
