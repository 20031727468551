import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Filters from "./Filters";
import FilledButton from "../../Buttons/Filled";
import { Container } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import Grid from "@material-ui/core/Grid";
import {
  PrimaryOutlineButton,
  SecondaryOutlineButton,
} from "../../Buttons/Outline";
import AddIcon from "@material-ui/icons/Add";
import { PrimaryIconButton } from "../../Buttons/Icon";
import TableDetails from "./Details";
import staticDataJson from "../../../data/data";

const rows = staticDataJson.transactions;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#F2F4F6",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: "#F2F4F6",
  },
  table: {
    width: "100%",
    backgroundColor: "#ffffff",
    marginBottom: 2,
    padding: "25px 0px 30px 20px",
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    paddingBottom: 15,
    width: 215,
  },
  recipient: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    verticalAlign: "top",
    paddingBottom: 15,
    width: 230,
  },
  amount: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    paddingBottom: 7,
    verticalAlign: "top",
  },
  type: {
    fontSize: 16,
    textAlign: "left",
    verticalAlign: "top",
    paddingTop: 5,
    paddingBottom: 15,
  },
  date: {
    fontSize: 16,
    textAlign: "left",
    verticalAlign: "top",
    paddingBottom: 15,
  },
  time: {
    fontSize: 16,
    textAlign: "left",
    color: "#ABABAB",
    verticalAlign: "top",
    paddingBottom: 30,
  },
  showMore: {
    textAlign: "center",
    marginTop: 60,
    marginBottom: "8em",
  },
  showMoreBtn: {},
  row: {
    height: 131,
  },
  label: {
    font: '"Roboto", sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "32px",
  },
}));

export function TxCard(props) {
  const classes = useStyles();
  const [expended, setExpend] = React.useState(false);
  const handleExpend = () => {
    setExpend(!expended);
  };
  return (
    <>
      <Grid item container xs={12} className={classes.table}>
        <Grid item xs={12} className={classes.label}>
          From
        </Grid>
        <Grid item xs={12} className={classes.name}>
          {props.row.from}
        </Grid>
        <Grid item xs={12} className={classes.label}>
          Recipient
        </Grid>
        <Grid item xs={12} className={classes.recipient}>
          {props.row.to}
        </Grid>
        <Grid item xs={12} className={classes.label}>
          Crypto Amount
        </Grid>
        <Grid item xs={12} className={classes.amount}>
          {props.row.amount} {props.row.crypto}
        </Grid>
        <Grid item xs={12} className={classes.label}>
          Type
        </Grid>
        <Grid item xs={12} className={classes.type}>
          {props.row.transactionType === "invested" ? (
            <SecondaryOutlineButton
              style={{
                borderRadius: 36,
                fontSize: 14,
                color: "#1CABE2",
                borderColor: "#1CABE2",
              }}
            >
              Investment made
            </SecondaryOutlineButton>
          ) : (
            <PrimaryOutlineButton style={{ borderRadius: 36, fontSize: 14 }}>
              Donation received
            </PrimaryOutlineButton>
          )}
        </Grid>
        <Grid item xs={12} className={classes.label}>
          Time
        </Grid>
        <Grid item xs={12} className={classes.time}>
          {props.row.details[1]
            ? props.row.details[1].time
            : props.row.details[0].time}
        </Grid>
        <Grid item xs={12}>
          <PrimaryIconButton
            style={{
              "&:hover": { textDecoration: "underline" },
              textTransform: "none",
              letterSpacing: 0,
              fontFamily: "'Prompt', sans-serif",
              fontSize: 16,
              fontWeight: "normal",
              padding: 0,
              lineHeight: "27px",
            }}
            endIcon={expended ? <RemoveIcon /> : <AddIcon />}
            onClick={handleExpend}
          >
            Transaction details
          </PrimaryIconButton>
        </Grid>
      </Grid>
      {expended ? (
        <Grid container item xs={12}>
          <TableDetails row={props.row.details} />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default function EnhancedTable() {
  const classes = useStyles();

  const [order] = React.useState("asc");
  const [filter, setFilter] = React.useState("");
  const [orderBy] = React.useState("recipient");
  const [page] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Container maxWidth={"lg"} style={{ padding: 0 }}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Filters onFilterChange={handleFilterChange} />
          <Grid container>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((row) => row.transactionType.includes(filter))
              .map((row, index) => {
                return <TxCard row={row} index={index} />;
              })}
          </Grid>
        </Paper>
        <div className={classes.showMore}>
          {rowsPerPage !== rows.length && (
            <FilledButton
              className={classes.showMoreBtn}
              onClick={() => {
                setRowsPerPage(Math.min(rowsPerPage + 5, rows.length));
              }}
            >
              Show more transactions
            </FilledButton>
          )}
        </div>
      </div>
    </Container>
  );
}
