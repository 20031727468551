import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import SearchIcon from "@material-ui/icons/Search";
// import IconButton from "@material-ui/core/IconButton";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import FilledButton from "../../Buttons/Filled";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    bottom: {
        fontFamily: "'Prompt', sans-serif",
        fontSize: 16,
        background: "#EAEDF0",
        padding: "25px 0px 30px 20px"
    },
    image: { width: 485 },
    subtitle: {
        fontSize: 16,
        color: "#374EA2",
    },
    sender: {
        fontSize: 20,
        color: "#374EA2",
    },
    header: {
        font: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "32px"
    },
    blueBox: {
        width: 220,
        background: "#1CABE2",
        padding: 20,
        color: "#fff",
    },
    name: {
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        paddingBottom: 15,
        width: 215
    },
    recipient: {
        fontSize: 16,
        fontWeight: 400,
        textAlign: "left",
        verticalAlign: 'top',
        paddingBottom: 15,
        width: 230
    },
    amount: {
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        paddingBottom: 7,
        verticalAlign: 'top'
    },
    type: {
        fontSize: 16,
        textAlign: "left",
        verticalAlign: 'top',
        paddingTop: 5,
        paddingBottom: 15,
    },
    date: {
        fontSize: 16,
        textAlign: "left",
        verticalAlign: 'top',
        paddingBottom: 15,
    },
    time: {
        fontSize: 16,
        textAlign: "left",
        color: "#ABABAB",
        verticalAlign: 'top',
    },
}));

export default function TableDetails({ row }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.bottom}>
                <Grid container className={classes.row}>
                    <Grid item xs={12} md className={classes.header}>
                        From
                    </Grid>
                    <Grid item xs={12} md className={classes.name}>
                        {row[0].from}
                    </Grid>
                    <Grid item xs={12} md className={classes.header}>
                        Recipient
                    </Grid>
                    <Grid item xs={12} md className={classes.recipient}>
                        {row[0].recipient}
                    </Grid>
                    <Grid item xs={12} md className={classes.header}>
                        Crypto amount
                    </Grid>
                    <Grid item xs={12} md className={classes.amount}>
                        <b style={{ fontWeight: 600 }}>{row[0].amount} {row[0].currency}</b>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md
                        className={classes.header}
                        style={{ position: "relative" }}
                    >
                        Transaction proof{" "}
                        <HelpOutlineIcon
                            style={{
                                top: 3,
                                position: "absolute",
                                marginLeft: 3,
                                color: "#374EA2",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md className={classes.type}>
                        <a href={row[0].link} style={{ color: "#374EA2" }} target="_blank" rel="noopener noreferrer">
                            Link
                        </a>
                    </Grid>
                    <Grid item xs={12} md className={classes.header}>
                        Time
                    </Grid>
                    <Grid item xs={12} md style={{ color: "#8E8E8E" }} className={classes.time}>
                        {row[0].time}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
