import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  textButton: {
    letterSpacing:0,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const btnStyles = {
  fontSize: 12,
  letterSpacing: 1.17,
  fontWeight: 700,
  fontFamily: '"Cabin", sans-serif',
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0)",
  },
};

const IconBtn = withStyles((theme) => ({
  root: {
    ...btnStyles,
  },
}))(Button);

const PrimaryBtn = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    ...btnStyles,
  },
}))(Button);

const SecondaryBtn = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    ...btnStyles,
  },
}))(Button);

export default function IconButton({
  disabled,
  startIcon,
  endIcon,
  onClick,
  float,
  children,
  style,
}) {
  const classes = useStyles();
  return (
    <IconBtn
      disabled={disabled}
      disableRipple={true}
      className={classes.textButton}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      onClick={onClick}
      style={{ float: float ? float : null, ...style }}
    >
      {children}
    </IconBtn>
  );
}

export function PrimaryIconButton({
  disabled,
  startIcon,
  endIcon,
  onClick,
  float,
  children,
  style,
}) {
  const classes = useStyles();
  return (
    <PrimaryBtn
      disabled={disabled}
      disableRipple={true}
      className={classes.textButton}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      onClick={onClick}
      style={{ float: float ? float : null, ...style }}
    >
      {children}
    </PrimaryBtn>
  );
}

export function SecondaryIconButton({
  disabled,
  startIcon,
  endIcon,
  onClick,
  float,
  children,
  style,
}) {
  const classes = useStyles();
  return (
    <SecondaryBtn
      disabled={disabled}
      disableRipple={true}
      className={classes.textButton}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      onClick={onClick}
      style={{ float: float ? float : null, ...style }}
    >
      {children}
    </SecondaryBtn>
  );
}
