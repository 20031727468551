import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Pagination from "../Pagination";
import Hero from "../Hero";
import staticDataJson from "../../data/data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    [theme.breakpoints.up("md")]: {
      paddingRight: "6em",
    },
  },
  pageTitle: {
    color: "#2d2926",
    marginTop: "2em",
    fontSize: 72,
    fontWeight: 400,
  },
  content: {
    [theme.breakpoints.up("md")]: {
      minHeight: 917
    }
  },
  mediaItem: {
    marginTop: 8,
    paddingRight: "1em",
    marginBottom: "4em",
  },
  thumbnail: {
    overflow: "hidden",
    width: "100%",
    maxHeight: 288,
  },
  mediaTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.3,
    color: "#2D2926",
    [theme.breakpoints.up("md")]: {
      fontSize: 27,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },

  link: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    textAlign: "right",
  },
  source: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 100,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  date: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    textAlign: "right",
    lineHeight: 1.3,
    marginTop: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  pagination: {
    [theme.breakpoints.up("md")]: {
      marginTop: 140,
    },
    marginBottom: 200,
  },
}));

export function MediaItem(props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4} className={classes.mediaItem}>
      <a
            style={{ textDecoration: "none", color: "#2D2926" }}
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
      <img
        src={require("../../data/" + props.image)}
        className={classes.thumbnail}
      />
      <Grid container className={classes.mediaContent}>
        <Grid item xs={12} className={classes.mediaTitle}>
          
            {props.title}
   
        </Grid>

        <Grid item xs={6} className={classes.source}>
          {props.link}
        </Grid>
        <Grid item xs={6} className={classes.date}>
          {props.date}
        </Grid>
      </Grid>
      </a>
    </Grid>
  );
}

export default function Media() {
  const classes = useStyles();
  const pageSize = 6;
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    const totalItems = 11;
    setData(staticDataJson.media);
    setTotalPages(Math.ceil(totalItems / pageSize));
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <Grid container className={classes.root}>
        <Hero title={"Media"} />
        <Grid container spacing={3} className={classes.content}>
          {data
            .slice(currentPage * pageSize - pageSize, currentPage * pageSize)
            .map((item, index) => {
              return <MediaItem {...item} key={index} />;
            })}
        </Grid>
        <Grid item xs={12} className={classes.pagination}>
          <Pagination
            currentItem={pageSize * currentPage}
            totalItems={data.length}
            itemName={"articles"}
            totalPages={totalPages}
            currentPage={currentPage}
            onClick={(newPage) => {
              setCurrentPage(newPage);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
