import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import TopBar from "./TopBar";

export default function Layout(props) {
    return (
        <React.Fragment>
            <TopBar/>
            <NavBar {...props} />
            {props.children}
            <Footer />
        </React.Fragment>
    );
}
