import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import donor from "./donor.png";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 120,
    marginBottom: 120,
    [theme.breakpoints.up("md")]: {
      marginTop: 220
    }
  },
  message: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    padding: 28,
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    lineHeight: 1.3,
    fontWeight: 400,
    [theme.breakpoints.up("md")]: {
      padding: "51px 50px 0px 52px",
      fontSize: 36,
      lineHeight: 1.22,
      maxHeight: 318,
      marginTop: -50,
      zIndex: 10,
    }
  },
  button: {
    marginTop: 30,
  },
  imageHolder: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "-9em",
    },
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      height: 424,
      maxWidth: "120%",
    },
  },
}));

export default function BecomeDonor() {
  const classes = useStyles();


  return (
    <Grid container className={classes.card}>
      <Grid item xs={12} md={6} className={classes.message}>
        <p style={{ margin: 0 }}>
          Interested in becoming a donor to the CryptoFund?
        </p>
        <a href="mailto:blockchain@unicef.org">
          <FilledButton style={{ marginTop: 30, marginBottom: 2 }}>
            Reach out to us
          </FilledButton>
        </a>
      </Grid>
      <Grid item xs={12} md={6} className={classes.imageHolder}>
        <img src={donor} className={classes.image} />
      </Grid>
    </Grid>
  );
}
