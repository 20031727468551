import React, { useEffect, useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import Hero from "../Hero";
import { SummarySection } from "../Summary";
import TransactionSection from "./TransactionSection";
// import heroImage from "./track.png";
import heroImage from "./CryptoFund_track_fund1.svg";
import { Container } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     maxWidth: "100%",
//   },
// }));

export default function Track() {
  const title = "Track funds";
  const description =
    "The UNICEF CryptoFund is a part of the organisation’s work to explore the transparent nature of public blockchains and the ability to quickly transfer assets globally. The CryptoFund allows anyone with an internet connection to confirm transfers have been made between donors, UNICEF and projects. The CryptoFund brings a new level of transparency and speed to the disbursement of funds.";

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
  });

  function Buttons() {
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <a href="#txs" style={{ textDecoration: "none" }}>
            <FilledButton>See CryptoFund transactions</FilledButton>
          </a>
        </Grid>
      </Grid>
    );
  }

  const [donorData] = useState({
    total: 2,
    label: "donors",
    btc: 24,
    eth: 1225,
  });
  const [investorData] = useState({
    total: 12,
    label: "investments",
    btc: 24,
    eth: 1225,
  });

  return (
    <>
      <Container maxWidth={"lg"}>
        <Grid container style={{ width: "100%" }}>
          <Grid item xs={12}>
            <Hero
              title={title}
              description={description}
              buttons={<Buttons />}
              image={heroImage}
            />
            <div style={{ marginTop: 200, marginBottom: 200 }}>
              <SummarySection
                primaryData={donorData}
                secondaryData={investorData}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div id="txs">
        <TransactionSection />
      </div>
      {/*<InfoSection />*/}
    </>
  );
}
