import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import contact from "./Contact.webp";
import TextField from "@material-ui/core/TextField";
import generalStyle from "../../util/style";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    paddingTop: 200,
    [theme.breakpoints.up("md")]: {
      paddingTop: 200,
      paddingBottom: 100,
    },
  },
  card: {
    marginBottom: 120,
  },
  title: {
    marginTop: 0,
    marginBottom: 30,
  },
  message: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    fontSize: 26,
    padding: 28,
    [theme.breakpoints.up("md")]: {
      padding: 50,
      marginTop: "-2em",
      zIndex: 10,
      height: 622,
    },
  },
  button: {
    marginTop: 30,
  },
  imageHolder: {
    [theme.breakpoints.up("md")]: {
      marginLeft: -90,
      width: "110%",
      height: 488,
    },
  },
  image: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // height: 488,
      width: "106%",
      height: "100%",
    },
  },
  textField: {
    fontFamily: "Roboto",
    width: "100%",
    outline: "none",
    borderRadius: 0,
    border: "2px solid #ffffff",
    backgroundColor: "rgba(0,0,0,0)",
    padding: 16,
    color: "#B1B7CF",
    fontSize: 18,
    marginTop: 12,
    marginBottom: 23,
  },
  multiTextField: {
    fontFamily: "Roboto",
    width: "100%",
    height: 219,
    outline: "none",
    borderRadius: 0,
    border: "2px solid #ffffff",
    backgroundColor: "rgba(0,0,0,0)",
    padding: 16,
    fontSize: 18,
    marginTop: 12,
    marginBottom: 12,
  },
  spacing: {
    margin: 25,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
    },
  },
}));

const multiTheme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: "100%",
        marginBottom: 13,
        height: 219,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 0,
        border: "2px solid",
        borderColor: "white",
        height: 219,
      },
      root: {
        borderRadius: 0,
        color: "white",
        fontFamily: "Roboto",
        fontSize: 14,
        "&:hover $notchedOutline": {
          borderColor: "white",
          border: "2px solid",
        },
        "&$focused $notchedOutline": {
          borderColor: "white",
          border: "2px solid",
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "#B1B7CF",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "white",
          fontFamily: "Roboto",
          fontSize: 16,
          fontHeight: 28,
        },
      },
    },
  },
});

const singleTheme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        width: "100%",
        marginBottom: 23,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 0,
        border: "2px solid",
        borderColor: "white",
      },
      root: {
        color: "white",
        fontFamily: "Roboto",
        fontSize: 14,
        borderRadius: 0,
        height: 54,
        "&:hover $notchedOutline": {
          borderColor: "white",
          border: "2px solid",
        },
        "&$focused $notchedOutline": {
          borderColor: "white",
          border: "2px solid",
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "#B1B7CF",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "white",
          fontFamily: "Roboto",
          fontSize: 16,
          fontHeight: 28,
        },
      },
    },
  },
});

function MultiLineText(props) {
  //const classes = useStyles();
  const [value] = useState(props.defaultValue || "");
  return (
    <ThemeProvider theme={multiTheme}>
      <TextField
        label={value}
        //className={classes.multiTextField}
        variant="outlined"
        multiline
        rows={8}
      ></TextField>
    </ThemeProvider>
    // <textarea
    //   className={classes.multiTextField}
    //   value={value}
    //   onKeyPress={(e) => {
    //     if (props.defaultValue === e.target.value) {
    //       setValue("");
    //       e.target.style.color="white";
    //     }
    //   }}
    //   onChange={(e) => {
    //     setValue(e.target.value);
    //   }}
    // >
    //   {value}
    // </textarea>
  );
}

function SingleLineText(props) {
  //const classes = useStyles();
  const [value] = useState(props.defaultValue || "");
  return (
    <ThemeProvider theme={singleTheme}>
      <TextField
        label={value}
        //className={classes.multiTextField}
        variant="outlined"
      ></TextField>
    </ThemeProvider>

    // <input
    //   type="text"
    //   className={classes.textField}
    //   value={value}
    //   onKeyPress={(e) => {
    //     if (props.defaultValue === e.target.value) {
    //       setValue("");
    //       e.target.style.color="white";
    //     }
    //   }}
    //   onChange={(e) => {
    //     setValue(e.target.value);
    //   }}
    // />
  );
}

function Contact() {
  const classes = useStyles();
  const style = generalStyle();
  return (
    <Grid container className={classes.card}>
      <Grid item xs={12} md={6} className={classes.message}>
        <h3 className={`${classes.title} ${style.title}`}>Contact us</h3>
        <SingleLineText defaultValue="Your email id" />
        <SingleLineText defaultValue="Subject" />
        <MultiLineText defaultValue="Type Message" />
        <FilledButton
          style={{ marginTop: 10, marginBottom: 2 }}
          primary={false}
        >
          Send Message
        </FilledButton>
      </Grid>
      <Grid item xs={12} md={7} className={classes.imageHolder}>
        <img src={contact} className={classes.image} />
      </Grid>
    </Grid>
  );
}

export default function (props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Grid item xs={12} md={10} className={classes.spacing}>
          <Contact />
        </Grid>
      </Container>
    </Grid>
  );
}
