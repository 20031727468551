import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import generalStyle from "../../util/style";

const useStyles = makeStyles((theme) => ({
  hero: {
    width: "100%",
    marginTop: 180,
    [theme.breakpoints.up("md")]: {
      marginTop: 220,
    },
  },
  title: {
    fontSize: 36,
    fontFamily: "Prompt",
    marginBottom: ".1em",
    lineHeight: "39px",
    fontWeight: "normal",
    [theme.breakpoints.up("md")]: {
      fontSize: 52,
      lineHeight: "79px"
    },
  },
  subtitle: {},
  description: {
    fontFamily: "Roboto",
    fontSize: 19,
    lineHeight: "34px",
  },
  image: {
    // width: 665,
    width: "100%",
    [theme.breakpoints.up("md")]:{
      paddingLeft: 65,
    },
  },
  contentRight: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 120,
    },
  },
}));

export default function Hero({
  title,
  subtitle,
  description,
  image = null,
  buttons,
  contentRight = null,
}) {
  const classes = useStyles();
  // const style = generalStyle();
  return (
    <section id="hero" className={classes.hero}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h1 className={`${classes.title}`}>{title}</h1>
            <h3 className={classes.subtitle}>{subtitle}</h3>
            <p className={classes.description}>{description}</p>
            {buttons}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            {image ? (
              <img src={image} className={classes.image} />
            ) : (
              <div className={classes.contentRight}>{contentRight}</div>
            )}
          </Grid>
        </Grid>
    </section>
  );
}
