import React, { useState } from "react";
//import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {createMuiTheme, NativeSelect} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// const useToolbarStyles = makeStyles((theme) => ({
//     itemText: {
//         display: "flex",
//         alignItems: "center",
//         paddingLeft: 25,
//         paddingRight: 25,
//         cursor: "pointer",
//         fontSize: 14,
//         fontWeight: 500,
//         color: theme.palette.primary.main,
//         textDecoration: "none",
//         height: 55,
//         borderBottom: "solid 3px rgba(0,0,0,0)",
//         "&:hover": {
//             color: "#ffffff",
//         },
//         [theme.breakpoints.up("md")]: {
//             height: 61,
//             fontSize: 16,
//         }
//     },
//     activeItemText: {
//         display: "flex",
//         alignItems: "center",
//         paddingLeft: 25,
//         paddingRight: 25,
//         cursor: "pointer",
//         fontSize: 14,
//         fontWeight: 500,

//         color: "#ffffff",
//         height: 55,
//         textDecoration: "none",
//         "&:hover": {
//             color: "#ffffff",
//         },
//         [theme.breakpoints.up("md")]: {
//             height: 61,
//             fontSize: 16,
//         }
//     },

//     item: {
//         "&:hover": {
//             backgroundColor: theme.palette.secondary.main,
//         },
//     },
//     activeItem: {
//         backgroundColor: theme.palette.primary.main,
//     },
// }));

// const filters = [
//     { type: "all", text: "All transactions" },
//     { type: "received", text: "Donations received" },
//     { type: "sent", text: "Investments made" },
// ];

const dropdownMenuTheme = createMuiTheme({
    overrides: {
        MuiNativeSelect:{
            root: {
                fontFamily: "Prompt",
                backgroundColor: "#374EA2",
                height: 55,
                fontSize: 14,
                fontWeight: 500,
                width: "100%",
                paddingLeft: 20,
                color: "white",
                '&:focused': {
                    backgroundColor: "",
                }
            },
            select:{
                '&:not([multiple]) option':{
                    backgroundColor: "#374EA2"
                }
            }
        },
        MuiSvgIcon: {
            root: {
                color: "white",
                marginLeft: -50,
                marginRight: 25
            }
        },
        MuiInputBase: {
            root: {
                fontSize: 14,
                fontWeight: 500,
                backgroundColor: "transparent",
                '&:focused': {
                    backgroundColor: "transparent",
                }
            }
        },
        MuiInput: {
            root: {
                border: 0,
                backgroundColor: "#374EA2",
            },
            underline: {
                '&::before': {
                    border: 0,
                },
                '&::after': {
                    border: 0,
                    backgroundColor: "#374EA2",
                },
                '&:hover':{
                    '@media (hover: none)':{
                        border: 0,
                        '&:not(.Mui-disabled):hover::before': {
                            border: 0,
                        },
                    },
                },
                '&:not(.Mui-disabled):hover::before': {
                    border: 0,
                },
            }
        }
    }
});

export default function Filters(props) {
    // const classes = useToolbarStyles();
    const [activeFilter, setActiveFilter] = useState("All transactions");

    function changeFilter(filter) {
        setActiveFilter(filter.target.value);
        if (filter.target.value === "Donations received"){
            props.onFilterChange("received")
        }
        else if (filter.target.value === "Investments made")
            props.onFilterChange("invested")
        else props.onFilterChange("")
    }
    return (
        <Grid container style={{backgroundColor: "#F2F4F6"}}>
            <Grid item xs={12}>
                <MuiThemeProvider theme={dropdownMenuTheme}>
                    <NativeSelect
                        value={activeFilter}
                        variant={"filled"}
                        onChange={changeFilter}
                        name="filter"
                        IconComponent={() => { return <ExpandMoreIcon/>}}
                        style={{width: "100%"}}
                    >
                        <option value="All transactions">All transactions</option>
                        <option value="Donations received">Donations received</option>
                        <option value="Investments made">Investments made</option>
                    </NativeSelect>
                </MuiThemeProvider>
            </Grid>
        </Grid>
    );
}
