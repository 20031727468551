import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import MenuPopper from "../../MenuPopper";

const useStyles = makeStyles((theme) => ({
  root: {},
  top: {
    [theme.breakpoints.up("md")]: {
      height: 215,
      padding: 50,
    },
  },
  bottom: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 16,
    background: "#EAEDF0",
    [theme.breakpoints.up("md")]: {
      padding: 20,
      paddingBottom: 40,
      paddingLeft: 35,
    },
  },
  image: { width: 485 },
  subtitle: {
    fontSize: 16,
    color: "#374EA2",
  },
  sender: {
    fontSize: 20,
    color: "#374EA2",
  },
  recipient: {
    fontSize: 20,
    fontWeight: 600,
    color: "#374EA2",
  },
  header: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    lineHeight: 2,
  },
  amount: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    lineHeight: 2,
  },
  blueBox: {
    width: 220,
    background: "#1CABE2",
    padding: 20,
    color: "#fff",
  },
  sent: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
  row: {
    marginTop: 9,
  },
  menuPopper: {
    width: 400,
    color: "#ffffff",
    backgroundColor: "#374EA2",
    padding: "24px 17px 22px 26px",
    fontSize: 20,
    lineHeight: 2.1,
    zIndex: 9999,
  },
  popperPosition: {
    paddingRight: 92,
    paddingBottom: 20,
  },
}));

export default function TableDetails({ row }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.bottom}>
        <Grid container className={classes.row}>
          <Grid item xs={12} md className={classes.header}>
            From
          </Grid>
          <Grid item xs={12} md className={classes.header}>
            Recipient
          </Grid>
          <Grid item xs={12} md className={classes.amount}>
            Crypto amount
          </Grid>
          {row[0].recovery && (
            <Grid item xs={12} md className={classes.amount}>
              Cost Recovery
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md
            className={classes.amount}
            style={{ position: "relative" }}
          >
            Transaction proof{" "}
            <MenuPopper
              arrowUp={false}
              placement={"top-start"}
              className={classes.popperPosition}
              button={
                <HelpOutlineIcon
                  style={{
                    top: 3,
                    position: "absolute",
                    marginLeft: 3,
                    color: "#374EA2",
                  }}
                />
              }
            >
              <Grid container className={classes.menuPopper}>
                <Grid item xs={12} style={{ fontSize: 16 }}>
                  Etherscan acts like a third party through which you can look
                  up, verify and validate your transactions. It is mainly a
                  read-only interface to the Ethereum blockchain.{" "}
                  <u>Learn more</u>
                </Grid>
              </Grid>
            </MenuPopper>
          </Grid>
          <Grid item xs={12} md>
            Time
          </Grid>
        </Grid>
        {row.map((line) => {
          return (
            <Grid container className={classes.row}>
              <Grid item xs={12} md>
                {line.from}
              </Grid>
              <Grid item xs={12} md>
                {line.recipient}
              </Grid>
              <Grid item xs={12} md>
                <b style={{ fontWeight: 600 }}>
                  {line.amount} {line.currency}
                </b>
              </Grid>

              {line.recovery && (
                <Grid item xs={12} md>
                  {line.recovery}
                </Grid>
              )}
              <Grid item xs={12} md>
                <a
                  href={line.link}
                  style={{ color: "#374EA2" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </Grid>
              <Grid item xs={12} md style={{ color: "#8E8E8E" }}>
                {line.time}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
