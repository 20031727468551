import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Transparent from "./Transparent.png";
import Fast from "./Fast.webp";
import Safe from "./Safe.png";
import generalStyle from "../../util/style";

const tiles = [
  {
    image: Transparent,
    title: "Transparent",
    description: `The transactions made in Cryptocurrency are easily traceable, since the transaction information gets registered on a public ledger.`,
  },
  {
    image: Fast,
    title: "Fast",
    description: `Cryptocurrencies allow for the global movement of funds at a rate that is faster than existing systems (2-3 days). The Bitcoin blockchain has a settlement time of around 60 minutes and the Ethereum blockchain has a settlement time of around 6 minutes.`,
  },
  {
    image: Safe,
    title: "Safe",
    description: `This technology relies on cryptography and immutability. This means that the information available on Bitcoin and Ethereum blockchain is kept in a way that is verifiable and permanent. No one can lie on these cryptocurrency technologies.`,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    maxWidth: "100%",
    justifyContent: "center",
  },
  subtitle: {
    margin: 0,
  },
  missionText: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.7,
    marginBottom: 50,
  },
  imageSpan: {
    minHeight: 120,
    display: "flex",
    alignItems: "center",
  },
  image: {
    maxWidth: 120,
    maxHeight: 120,
  },
  tileTitle: {
    fontFamily: "'Prompt', sans-serif",
    fontSize: 26,
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 15,
    color: "#374EA2",
  },
  description: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.8,
    marginBottom: 60,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
  mission: {
    padding: 26,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      paddingRight: "30em",
    },
  },
  missionTiles: {
    padding: 26,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
    },
  },
}));

export default function Why(props) {
  const classes = useStyles();
  const style = generalStyle();
  function Tile(props) {
    return (
      <Grid item xs={12} md={4}>
        <div className={classes.imageSpan}>
          <img src={props.image} className={classes.image} />
        </div>
        <h4 className={classes.tileTitle}>{props.title}</h4>
        <p className={classes.description}>{props.description}</p>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid item xs={12} md={10} className={classes.mission}>
            <h3 className={`${classes.subtitle} ${style.title}`}>
              Why Cryptocurrencies?
            </h3>
            <p className={classes.missionText}>
              Leveraging the benefits of blockchain technology, the CryptoFund
              aspires to create visibility for donors and the public, adding a
              layer of transparent accounting to the donation process.
            </p>
          </Grid>
        </Container>
        <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid item xs={12} md={10} className={classes.missionTiles}>
            <Grid container spacing={6}>
              {tiles.map((tile, index) => {
                return (
                  <Tile
                    key={index}
                    image={tile.image}
                    title={tile.title}
                    description={tile.description}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
