import { createMuiTheme } from "@material-ui/core/styles";

// This can be driven by a api/database call to customize for each deploy
const appSettings = {
  logoUrl: "/image/1601918615229-UNICEF.png",
  primaryColor: "#374EA2",
  lightPrimaryColor: "#5569b0",
  darkPrimaryColor: "#374EA2",
  secondaryColor: "#1cabe2",
  lightSecondaryColor: "#49bce8",
  darkSecondaryColor: "#1cabe2",
  containedButtonHover: "#33bef2",
  containedButtonActive: "#0094cb",
  textButtonHover: "#ecfaff",
};

// App and component default settings
export default createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  palette: {
    primary: {
      main: appSettings.primaryColor,
      light: appSettings.lightPrimaryColor,
      dark: appSettings.darkPrimaryColor,
      containedHover: appSettings.containedButtonHover,
      containedActive: appSettings.containedButtonActive,
      textHover: appSettings.textButtonHover,
    },
    secondary: {
      main: appSettings.secondaryColor,
      light: appSettings.lightSecondaryColor,
      dark: appSettings.darkSecondaryColor,
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: '"Prompt",  sans-serif',
    fontSize: 12,
    fontWeightRegular: 400,
    color: "#2D2926",
  },
});
