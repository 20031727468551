import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useToolbarStyles = makeStyles((theme) => ({
  itemText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: "none",
    height: 55,
    borderBottom: "solid 3px rgba(0,0,0,0)",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      height: 61,
      fontSize: 16,
    }
  },
  activeItemText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 500,

    color: "#ffffff",
    height: 55,
    textDecoration: "none",
    "&:hover": {
      color: "#ffffff",
    },
    [theme.breakpoints.up("md")]: {
      height: 61,
      fontSize: 16,
    }
  },

  item: {
    "&:hover": {

    },
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const filters = [
  { type: "all", text: "All transactions" },
  { type: "received", text: "Donations received" },
  { type: "sent", text: "Investments made" },
];

export default function Filters(props) {
  const classes = useToolbarStyles();
  const [activeFilter, setActiveFilter] = useState("All transactions");

  function changeFilter(filter) {
    setActiveFilter(filter.target.textContent);
    if (filter.target.textContent === "Donations received"){
      props.onFilterChange("received")
    }
    else if (filter.target.textContent === "Investments made")
      props.onFilterChange("invested")
    else props.onFilterChange("")
  }
  return (
    <Grid container style={{backgroundColor: "#F2F4F6"}}>
      {filters.map((filter, index) => {
        return (
          <Grid
            item
            className={
              activeFilter === filter.text ? classes.activeItem : classes.item
            }
            onClick={changeFilter}
            key={index}
          >
            <div
              className={
                activeFilter === filter.text
                  ? classes.activeItemText
                  : classes.itemText
              }
            >
              {filter.text}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
