const staticDataJson = {
  "projects": [
    {
      "investmentName": "Convexity",
      "country": "Nigeria",
      "projectLink": "https://withconvexity.com/",
      "header": "Transparent digital cash and voucher transfer to beneficiaries through blockchain",
      "image": "./chats.jpeg",
      "imageCredit": "© UNICEF",
      "toType": "Recipient",
      "amount": 52.2388,
      "currency": "ETH",
      "transactionProof": [
        {
          "from": "UNICEF HQ",
          "recipient": "Convexity",
          "amount": 52.2388,
          "currency": "ETH",
          "time": "13 Dec 2022 21:27 UTC",
          "link": "https://etherscan.io/tx/0x07819f43f60784877a5427fcbe66b8f13d07dd0cea7be85c1578f569d0b2be1d"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "InvestTools",
      "country": "Brazil",
      "projectLink": "https://investtools.com.br/",
      "header": "Giga token to incentive connectivity to schools around the globe",
      "image": "./GigaToken.jpeg",
      "imageCredit": "© UNICEF",
      "toType": "Recipient",
      "amount": 51.8339,
      "currency": "ETH",
      "transactionProof": [
        {
          "from": "UNICEF HQ",
          "recipient": "InvestTools",
          "amount": 51.8339,
          "currency": "ETH",
          "time": "13 Dec 2022 21:25 UTC",
          "link": "https://etherscan.io/tx/0xc5c1bca8ead2e021eb57e28de44b134b56b68fae009b631bd18292973b7ce84e"
        }
      ],
      
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "XMesh",
      "country": "Nigeria",
      "projectLink": "https://www.linkedin.com/in/qbyan/",
      "header": "Equitable, affordable Internet access for remote and underserved areas",
      "image": "./xOneFi.jpeg",
      "imageCredit": "© UNICEF",
      "toType": "Recipient",
      "amount": 46.64,
      "currency": "ETH",
      "transactionProof": [
        {
          "from": "UNICEF HQ",
          "recipient": "XMesh",
          "amount": 46.64,
          "currency": "ETH",
          "time": "13 Dec 2022 21:26 UTC",
          "link": "https://etherscan.io/tx/0xa54da02943751ce53d8f4306e1c0235d5c5a72711f049bbf4d327bf493cae87a"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "WiiQare",
      "country": "Democratic Republic Of Congo",
      "projectLink": "https://wiiqare.com/",
      "header": "An all-in-one digital platform for healthcare savings, payments, and remittances",
      "image": "./wiiQare.jpeg",
      "imageCredit": "© UNICEF",
      "toType": "Recipient",
      "amount": 43.52,
      "currency": "ETH",
      "transactionProof": [
        {
          "from": "UNICEF HQ",
          "recipient": "WiiQare",
          "amount": 43.52,
          "currency": "ETH",
          "time": "13 Dec 2022 21:23 UTC",
          "link": "https://etherscan.io/tx/0x93b7b44f4563e186b00d68749a63c52a67550b7a5a8736bc1360ad912b18202e"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Rentadrone",
      "amount": 73.2,
      "currency": "ETH",
      "country": "Chile",
      "projectLink": "https://rentadrone.cl/",
      "header": "ML Image analysis of drone pictures for solar panel faults and precision agriculture",
      "image": "./Rentadrone.jpg",
      "imageCredit": "© Rentadrone",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xf29463209bb1e5c59d83c950849b952958b33b1344daa48345f9b4a482c3ac09#internal",
          "time": "21 Apr 2022 17:09 UTC",
          "amount": 73.2,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Rentadrone"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Bookbot",
      "amount": 21.984,
      "currency": "ETH",
      "country": "Indonesia",
      "projectLink": "https://www.bookbotkids.com/",
      "header": "Speech recognition app that listens to children read and helps them with pronunciation",
      "image": "./Bookbot.png",
      "imageCredit": "© Bookbot Technology",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x6ab38494d13376d93d68bb2b5f65307c686632eb28845274473ea6e9c2edf16e#internal",
          "time": "21 Apr 2022 16:54 UTC",
          "amount": 21.984,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Bookbot"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "AfriLearn",
      "amount": 1.2734,
      "currency": "BTC",
      "country": "Nigeria",
      "projectLink": "https://myafrilearn.com/",
      "header": "Application for accessible, adaptive learning based on West-African curricula",
      "image": "./AfriLearn.jpg",
      "imageCredit": "© UNICEF UN0396244 Owoicho",
      "transactionProof": [
        {
          "link": "https://www.blockchain.com/btc/tx/8143f7d49c2b3219cc0cc8c207d1f234d827a7e839ec267a9112a62933ae58b1",
          "time": "21 Apr 2022 21:27 UTC",
          "amount": 1.2734,
          "currency": "BTC",
          "from": "UNICEF HQ",
          "recipient": "AfriLearn"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Eyebou",
      "amount": 24.1404,
      "currency": "ETH",
      "country": "UAE",
      "projectLink": "https://www.eyebou.com/",
      "header": "AI tool for virtual eye tests to detect vision disorders in children",
      "image": "./Eyebou.jpg",
      "imageCredit": "© UNICEF UN057765 Izhiman",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x3556292ab98bdd0345cc739fc64d98896230eb066626c032f4851d1245e06237#internal",
          "time": "21 Apr 2022 16:52 UTC",
          "amount": 24.1404,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Eyebou"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Portal Telemedicina",
      "amount": 10.342,
      "currency": "ETH",
      "country": "Brazil",
      "projectLink": "https://portaltelemedicina.com.br/en/",
      "header": "Providing reliable, fast, low-cost online medical diagnostics to 300+ cities in Brazil and Africa",
      "image": "./PortalTelemedicina.jpg",
      "imageCredit": "© UNICEF UN0493975 Santos",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xccc47f5d05b86ee89f90aaef91ed03c2093823186a7e845069fa9932bdcc619a#internal",
          "time": "21 Apr 2022 16:50 UTC",
          "amount": 10.342,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Portal Telemedicina"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Bioverse",
      "amount": 66.0001,
      "currency": "ETH",
      "country": "Brazil",
      "projectLink": "https://www.bioverse.io",
      "header": "Using drones and AI to support the sustainability of the amazonian ecosystem",
      "image": "./BioverseLabs.png",
      "imageCredit": "© Bioverse Labs",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x9966034e0c2ca6c5850c67f7c7e6b8b53a84aca91a131368c3b862d51a45fca6#internal",
          "time": "21 Apr 2022 16:28 UTC",
          "amount": 66.0001,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Bioverse"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "OTTAA",
      "amount": 69.3561,
      "currency": "ETH",
      "country": "Chile",
      "projectLink": "https://www.ottaaproject.com",
      "header": "AI enabled communication assistant for children with speech impairments using AI and pictograms",
      "image": "./OTTAA.png",
      "imageCredit": "© Chivas Venture Argentina Media",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x2a320e7772b8340930da8b73a01259aa819b59a0f2b06c294384af4c9f20e096#internal",
          "time": "21 Apr 2022 17:16 UTC",
          "amount": 69.3561,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "OTTAA"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Angaza Elimu",
      "amount": 70.66,
      "currency": "ETH",
      "country": "Kenya",
      "projectLink": "https://angazaelimu.com",
      "header": "AI powered eLearning platform that delivers personalized and mastery based learning experiences",
      "image": "./AngazaElimu.jpg",
      "imageCredit": "© Angaza Elimu",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x401f37241d88edf9ac7d9e6040f23f36be3c594da8ff0723fcc22bca00d79260#internal",
          "time": "21 Apr 2022 17:18 UTC",
          "amount": 70.66,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Angaza Elimu"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Dronfies",
      "amount": 58,
      "currency": "ETH",
      "country": "Uruguay",
      "projectLink": "www.dronfies.com",
      "header": "UTM (unmannded traffic management system) to provide smooth coordination of drone services",
      "image": "./Dronfies.jpg",
      "imageCredit": "© Dronfies Labs",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xaa162740ac2c9e37c89e49c7cfa858412d5dbc5d3d78f54b12521988d78ee6f9#internal",
          "time": "21 Apr 2022 17:21 UTC",
          "amount": 58,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Dronfies"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Cirrolytix",
      "amount": 8.204,
      "currency": "ETH",
      "country": "Philippines",
      "projectLink": "https://cirrolytix.com/",
      "header": "Platform for dengue prediction using climate and health data for epidemic management",
      "image": "./Cirrolytix.jpg",
      "imageCredit": "© UNICEF UN0248153 Noorani",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x4eeb33d66ec13d1ba31df934c7137871cdae5c850bf2d144bcddf6351666443c#internal",
          "time": "21 Apr 2022 17:19 UTC",
          "amount": 8.204,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Cirrolytix"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Om3ga",
      "amount": 0.604,
      "currency": "BTC",
      "country": "Serbia",
      "projectLink": "https://om3ga.org/",
      "header": "Deep learning speech-to-text solution for Slavic languages, integrated with a chatbot builder",
      "image": "./Om3ga.jpg",
      "imageCredit": "© UNICEF UN0352692 Vas",
      "transactionProof": [
        {
          "link": "https://www.blockchain.com/btc/tx/9b1be85c7d699cb1e1eb47ab399d7407e488ab61adea7564e97ec0b95a87e16e",
          "time": "21 Apr 2022 21:20 UTC",
          "amount": 0.604,
          "currency": "BTC",
          "from": "UNICEF HQ",
          "recipient": "Om3ga"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Somleng",
      "amount": 54.17,
      "currency": "ETH",
      "country": "Cambodia",
      "projectLink": "https://www.somleng.org/",
      "header": "Using a low-cost Interactive Voice Response platform to send key information about COVID-19 in Cambodia",
      "image": "./Somleng.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xa8547a2f3fc7fd1014018ab4d40e5f2626dac2a6885f024642b123b45bb2bf64",
          "time": "22 Dec 2021 16:18 UTC",
          "amount": 54.17,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Somleng"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Dymaxion Labs",
      "amount": 47.08,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "https://www.unicef.org/innovation/stories/unicef-innovation-fund-graduate-dymaxion-labs",
      "header": "Surveying large areas using AI-powered geospatial analytics to help better inform public policy",
      "image": "./Dymaxion.png",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x07d92a3b896d7743495130bb58928c00f6cdeac2ee5bb6b801883767a622c8e1",
          "time": "22 Dec 2021 16:29 UTC",
          "amount": 47.08,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Dymaxion Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "StaTwig",
      "amount": 43.43,
      "currency": "ETH",
      "country": "India",
      "projectLink": "https://statwig.com/",
      "header": "Improving food and vaccines distribution systems more efficiently through blockchain",
      "image": "./StaTwig.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x8be58e3bbb5391117e127409b73631ff602aaebb177a0a8105612655ad940f8b",
          "time": "22 Dec 2021 16:31 UTC",
          "amount": 43.43,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "StaTwig"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Weni (prev. Ilhasoft)",
      "amount": 54.16,
      "currency": "ETH",
      "country": "Brazil",
      "projectLink": "https://www.unicef.org/innovation/stories/unicef-innovation-fund-graduate-ilhasoft",
      "header": "Revolutionizing Natural Language Processing for everyone",
      "image": "./Weni.png",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xd8ba361e031a7062db8ca0e456115330b7bcc9c94266b9b61c345dfb3caff849",
          "time": "22 Dec 2021 16:32 UTC",
          "amount": 54.16,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Weni (prev. Ilhasoft)"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Xcapit",
      "amount": 24.04,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/xcapit-building-platform-using-blockchain-and-ai-increase-easy-safe-access",
      "header": "Building a platform using blockchain and AI to increase easy, safe access to financial services",
      "image": "./Xcapit.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xa2160e91fcdeb3693b53dc37c3f6f60e040f26ed828aef2a5518d0559256f56d",
          "time": "14 Dec 2021 17:40 UTC",
          "amount": 24.04,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Xcapit"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Kotani Pay",
      "amount": 12.47,
      "currency": "ETH",
      "country": "Kenya",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/kotani-pay-increasing-access-financial-instruments-using-blockchain-and",
      "header": "Increasing access to financial instruments using blockchain and cryptocurrency",
      "image": "./KotaniPay.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x74deb090250e8fed461820b573a06f09c269333068e46b63acf7330e6c71ff73",
          "time": "14 Dec 2021 17:46 UTC",
          "amount": 12.47,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Kotani Pay"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "BX Smart Labs",
      "amount": 9.7,
      "currency": "ETH",
      "country": "Mexico",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/bx-smart-labs-developing-decentralized-app-saving-circles",
      "header": "Developing a decentralized app for saving circles",
      "image": "./BXSmart.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x4985431e95a2648d4cc00cb85fb482854ca71e7b4a974aa092c168a48ff94320",
          "time": "14 Dec 2021 17:48 UTC",
          "amount": 9.7,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "BX Smart Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Atix Labs",
      "amount": 53.47,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "https://www.unicef.org/innovation/FundGraduate/Atixlabs",
      "header": "UNICEF Innovation Fund Graduate: Atix Labs",
      "image": "./Atix.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xbccfed31fe0993edf4d8f1859ba0ad605e1b93fed7518e7dfe551756a953bca8",
          "time": "14 Dec 2021 17:51 UTC",
          "amount": 53.47,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Atix Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Grassroots Economics",
      "amount": 25.11,
      "currency": "ETH",
      "country": "Kenya",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/grassroots-economics-integrating-emergency-response-platform-stakeholders-access",
      "header": "Integrating an emergency response platform for stakeholders to access digital payments",
      "image": "./Grassroot.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xf26612f7f21d6f73a5cb0172265218ae1ede79f4765c5bd6191c530a3b353669",
          "time": "15 Jun 2021 06:39 UTC",
          "amount": 25.11,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Grassroots Economics"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Xcapit",
      "amount": 19.73,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/xcapit-building-platform-using-blockchain-and-ai-increase-easy-safe-access",
      "header": "Building a platform using blockchain and AI to increase easy, safe access to financial services",
      "image": "./Xcapit.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x7ddab44045dfc0222e590a9b189e0d0f80ae87815604d924cb96c12024cf6423",
          "time": "15 Jun 2021 06:37 UTC",
          "amount": 19.73,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Xcapit"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Leaf Global Fintech",
      "amount": 11.78,
      "currency": "ETH",
      "country": "Rwanda",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/leaf-wallet-digital-financial-services-refugees-and-under-resourced-communities",
      "header": "Digital financial services for refugees and under-resourced communities",
      "image": "Leaf.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xd1baa35eece343105f536eaab82f776307baebb7d3d68492dfac60f5e5ebd73b",
          "time": "18 Jun 2021 06:30 UTC",
          "amount": 11.78,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Leaf Global Fintech"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "BX Smart Labs",
      "amount": 9.17,
      "currency": "ETH",
      "country": "Mexico",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/bx-smart-labs-developing-decentralized-app-saving-circles",
      "header": "Developing a decentralized app for saving circles",
      "image": "./BXSmart.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x31528d277a8cd70b38ad355ad2faf8086345e75a92f3ea422d1243c9fd9f4716",
          "time": "15 Jun 2021 06:41 UTC",
          "amount": 9.17,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "BX Smart Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Kotani Pay",
      "amount": 8.91,
      "currency": "ETH",
      "country": "Kenya",
      "projectLink": "https://www.unicefinnovationfund.org/broadcast/updates/kotani-pay-increasing-access-financial-instruments-using-blockchain-and",
      "header": "Increasing access to financial instruments using blockchain and cryptocurrency",
      "image": "./KotaniPay.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xf430623b2c3dd13f62496225aee7ddb882b4a4f58cc0cd47bf3e629c0134f4e9",
          "time": "15 Jun 2021 06:44 UTC",
          "amount": 8.91,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Kotani Pay"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "OS City",
      "amount": 27,
      "currency": "ETH",
      "country": "Mexico",
      "projectLink": "https://www.unicef.org/innovation/stories/transparent-provable-and-immutable-acknowledgements",
      "header": "Transparent, Provable, and Immutable Acknowledgements",
      "image": "./OS.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xcd30bc97e89ef48ae159880a2e541817fc79934b39a370bc7b756d6052dbc855",
          "time": "27 May 2021 08:21 UTC",
          "amount": 27,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "OS City"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "OS City + Atix Labs",
      "amount": 125,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "https://insureblocks.com/ep-120-unicef-venture-fund-case-studies-os-city-atix-labs-and-statwig/",
      "header": "Leveraging AI to analyse sentiment analysis and response of public services",
      "image": "./OS-2.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x881969e942992404eabfa95602f1fe197f21571933b69352bbcfcd952fefa178",
          "time": "12 Oct 2020 05:08 UTC",
          "amount": 62.5,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "OS City"
        },
        {
          "link": "https://etherscan.io/tx/0x7149a1e1fb2223ccbf968786ebdda078eb6c32f0953cd145925acd60f44de639",
          "time": "12 Oct 2020 05:08 UTC",
          "amount": 62.5,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Atix Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Atix Labs",
      "amount": 1,
      "currency": "BTC",
      "country": "Argentina",
      "projectLink": "https://www.unicef.org/innovation/FundGraduate/Atixlabs",
      "header": "UNICEF Innovation Fund Graduate: Atix Labs",
      "image": "./Atix.jpg",
      "transactionProof": [
        {
          "link": "https://btc4.trezor.io/tx/001ef6f14df3b63f872c4cbdf7d3f36a15330eb9b9db4983f6c3b6d9093a8924",
          "time": "08 Oct 2019 17:16 UTC",
          "amount": 1,
          "currency": "BTC",
          "from": "UNICEF HQ",
          "recipient": "Atix Labs"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Prescrypto",
      "amount": 49.5,
      "currency": "ETH",
      "country": "Mexico",
      "projectLink": "https://www.unicef.org/innovation/fundgraduate/Prescrypto",
      "header": "Making sensitive clinical data portable, safe and private",
      "image": "./Prescrypto.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x63b15553266e673f73315b93f9914a92d8208dfe8fa1d01dc119d156dc33d622",
          "time": "08 Oct 2019 15:54 UTC",
          "amount": 49.5,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Prescrypto"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Utopixar",
      "amount": 49.5,
      "currency": "ETH",
      "country": "Tunisia",
      "projectLink": "https://www.unicef.org/innovation/fundgraduate/Coinsence",
      "header": "Using blockchain technology to inspire young people to become local changemakers",
      "image": "./Utopixar.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x5735e0524e936818369f2eac2ef7c917b74f00993d187ffdb738e287032e70a0",
          "time": "08 Oct 2019 15:57 UTC",
          "amount": 49.5,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Utopixar"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Somleng",
      "amount": 125,
      "currency": "ETH",
      "country": "Cambodia",
      "projectLink": "https://www.somleng.org/",
      "header": "Using a low-cost Interactive Voice Response platform to send key information about COVID-19 in Cambodia",
      "image": "./Somleng.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xea13d93f88bc5cc1c86b0c57e60ef0bd6a6c4446c623acc2622da7fbcbfec3e8",
          "time": "18 Jun 2020 08:06 UTC",
          "amount": 0,
          "currency": "",
          "from": "UNICEF HQ",
          "recipient": "Somleng"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Avyantra",
      "amount": 125,
      "currency": "ETH",
      "country": "India",
      "projectLink": "https://www.unicef.org/innovation/stories/avyantra-using-machine-learning-facilitate-early-treatment-infants-neonatal-sepsis",
      "header": "Using machine learning to facilitate early treatment to infants with neonatal sepsis",
      "image": "./Avyantra.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0xe7951d7a78fa3581c48b8914c7f2c5737aeeab29989f65763c21bd1fa2134b06",
          "time": "18 Jun 2020 07:50 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Avyantra"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "StaTwig",
      "amount": 125,
      "currency": "ETH",
      "country": "India",
      "projectLink": "https://statwig.com/",
      "header": "Improving food and vaccines distribution systems more efficiently through blockchain",
      "image": "./StaTwig.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x36a083becc6d49514c4fa8dc61ea398f01be0e681d9b79d640eccbf5bd4d80e4",
          "time": "18 Jun 2020 07:52 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "StaTwig"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Utopic Studio",
      "amount": 125,
      "currency": "ETH",
      "country": "Chile",
      "projectLink": "https://utopicstudio.com/",
      "header": "Improving children's reading skills using VR technology",
      "image": "./UtopicStudio.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x30e928db6a6eb5d88bbdb8c19202308047683be8dd4b4f80fbe49db6c9e56cb9",
          "time": "18 Jun 2020 07:41 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Utopic Studio"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Ideasis",
      "amount": 125,
      "currency": "ETH",
      "country": "Turkey",
      "projectLink": "http://www.ideasis.com.tr/",
      "header": "Utilizing VR technology to address phobias and social anxieties",
      "image": "./Ideasis.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x776e0b64c76f58b7521e4e6de44026cfb6f2aa7186bc127b302544faabf83c5a",
          "time": "18 Jun 2020 07:52 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Ideasis"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Afinidata",
      "amount": 125,
      "currency": "ETH",
      "country": "Guatemala",
      "projectLink": "https://afinidata.com/en/home/",
      "header": "Using artificial intelligence to provide access to quality early childhood education",
      "image": "./Afinidata.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x5a20f875d51ec96726ae484e9083cb3a93b0d2ced74692f473f8822b8722f20e",
          "time": "18 Jun 2020 07:52 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Afinidata"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "Cireha",
      "amount": 125,
      "currency": "ETH",
      "country": "Argentina",
      "projectLink": "http://cireha.com.ar/",
      "header": "Aiding communication for children and adults with speech and language impairments",
      "image": "./Cireha.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x5f7fa39683f421dc98b0981feef0573bd805293d38dfdbaa7bcab4438175c3bd",
          "time": "18 Jun 2020 07:47 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "Cireha"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    },
    {
      "investmentName": "OS City",
      "amount": 125,
      "currency": "ETH",
      "country": "Mexico",
      "projectLink": "https://www.unicef.org/innovation/stories/one-smart-creating-data-driven-verified-cities-through-blockchain-and-ai",
      "header": "Creating data-driven, verified cities through blockchain and AI",
      "image": "./OSCity.jpg",
      "transactionProof": [
        {
          "link": "https://etherscan.io/tx/0x7df0ef84451e66e26046bb896c9ea7d4ab589b97c3fa80c080d18d0b3e22be1b",
          "time": "18 Jun 2020 07:44 UTC",
          "amount": 125,
          "currency": "ETH",
          "from": "UNICEF HQ",
          "recipient": "OS City"
        }
      ],
      "allocations": {
        "blockchainDevelopment": 0,
        "nonBlockchainDevelopment": 0,
        "security": 0,
        "growingPeople": 0,
        "growingProduct": 0,
        "growingOpenSource": 0
      }
    }
  ],
  "transactions": [

    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "To Mars LLC (xCapit)",
      "toType": "Recipient",
      "amount": 5.40250,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "To Mars LLC (xCapit)",
          "amount": 5.40250,
          "currency": "ETH",
          "time": "14 Sep 2023 16:02 UTC",
          "link": "https://etherscan.io/tx/0x15e89e24e4892017c5d362d3cfa013d0c8a23da27b9a218c847358d15975dc0e"
        }
      ]
    },




    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "WiiQare",
      "toType": "Recipient",
      "amount": 34.64190,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "WiiQare",
          "amount": 34.64190,
          "currency": "ETH",
          "time": "14 Sep 2023 16:02 UTC",
          "link": "https://etherscan.io/tx/0x15e89e24e4892017c5d362d3cfa013d0c8a23da27b9a218c847358d15975dc0e"
        }
      ]
    },



    
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Rentadrone",
      "toType": "Recipient",
      "amount": 12,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Rentadrone",
          "amount": 12,
          "currency": "ETH",
          "time": "15 May 2023 13:59 UTC",
          "link": "https://etherscan.io/tx/0xee49ed8f673601464478903eedc5ccce10baf47ce13961a76a4e847eed655c91"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Dronfies",
      "toType": "Recipient",
      "amount": 24,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Dronfies",
          "amount": 24,
          "currency": "ETH",
          "time": "15 May 2023 13:59 UTC",
          "link": "https://etherscan.io/tx/0xee49ed8f673601464478903eedc5ccce10baf47ce13961a76a4e847eed655c91"
        }
      ]
    },

    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Bioverse",
      "toType": "Recipient",
      "amount": 12,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Bioverse",
          "amount": 12,
          "currency": "ETH",
          "time": "15 May 2023 13:57 UTC",
          "link": "https://etherscan.io/tx/0x5af4536b32bfbcdbfe008d3415b59ccd84f5532281b479dfd8b9760f72a36ec4"
        }
      ]
    },


    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Angaza Elimu",
      "toType": "Recipient",
      "amount": 12.110,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Angaza Elimu",
          "amount": 12.110,
          "currency": "ETH",
          "time": "15 May 2023 13:55 UTC",
          "link": "https://etherscan.io/tx/0xea2cc3401066479e1f1cce42f7e55a31151e2268a460ca22e54669e65ce311d5"
        }
      ]
    },





    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Deloitte",
      "toType": "Recipient",
      "amount": 14.7530,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Deloitte",
          "amount": 14.7530,
          "currency": "ETH",
          "time": "13 Dec 2022 21:34 UTC",
          "link": "https://etherscan.io/tx/0x26424f47dd26735ceb724dc65689951409ee28f333a11612dcc97847d096f1f1"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Gitcoin",
      "toType": "Recipient",
      "amount": 50,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Gitcoin",
          "amount": 50,
          "currency": "ETH",
          "time": "13 Dec 2022 21:29 UTC",
          "link": "https://etherscan.io/tx/0xa240acd60ca3a8b21580b7a812084640c102c27535a8cd03a32ade803714dafb"
        }
      ]
    }, {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Convexity",
      "toType": "Recipient",
      "amount": 52.2388,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Convexity",
          "amount": 52.2388,
          "currency": "ETH",
          "time": "13 Dec 2022 21:27 UTC",
          "link": "https://etherscan.io/tx/0x07819f43f60784877a5427fcbe66b8f13d07dd0cea7be85c1578f569d0b2be1d"
        }
      ]
    }, {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "XMesh",
      "toType": "Recipient",
      "amount": 46.64,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "XMesh",
          "amount": 46.64,
          "currency": "ETH",
          "time": "13 Dec 2022 21:26 UTC",
          "link": "https://etherscan.io/tx/0xa54da02943751ce53d8f4306e1c0235d5c5a72711f049bbf4d327bf493cae87a"
        }
      ]
    },{
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "InvestTools",
      "toType": "Recipient",
      "amount": 51.8339,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "InvestTools",
          "amount": 51.8339,
          "currency": "ETH",
          "time": "13 Dec 2022 21:25 UTC",
          "link": "https://etherscan.io/tx/0xc5c1bca8ead2e021eb57e28de44b134b56b68fae009b631bd18292973b7ce84e"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "WiiQare",
      "toType": "Recipient",
      "amount": 43.52,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "WiiQare",
          "amount": 43.52,
          "currency": "ETH",
          "time": "13 Dec 2022 21:23 UTC",
          "link": "https://etherscan.io/tx/0x93b7b44f4563e186b00d68749a63c52a67550b7a5a8736bc1360ad912b18202e"
        }
      ]
    },


    

      {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Bioverse",
      "toType": "Recipient",
      "amount": 28.1599,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Bioverse",
          "amount": 28.1599,
          "currency": "ETH",
          "time": "9 Dec 2022 17:59 UTC",
          "link": "https://etherscan.io/tx/0xa95142f3243708956cae7b6e8535a2ceeded6ac779255522963820034c70e1c2"
        }
      ]
    },

    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Planet Angaza",
      "toType": "Recipient",
      "amount": 26.81,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Planet Angaza",
          "amount": 26.81,
          "currency": "ETH",
          "time": "9 Dec 2022 17:35 UTC",
          "link": "https://etherscan.io/tx/0x032333bed54d3248f80d2e67d0c97ac190e5f45cfc8909b6c15d7854ca400051"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Dronefies",
      "toType": "Recipient",
      "amount": 22.38,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Dronefies",
          "amount": 22.38,
          "currency": "ETH",
          "time": "9 Dec 2022 17:35 UTC",
          "link": "https://etherscan.io/tx/0x032333bed54d3248f80d2e67d0c97ac190e5f45cfc8909b6c15d7854ca400051"
        }
      ]
    },



  {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Afrilearn",
      "toType": "Recipient",
      "amount": 0.6901,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Afrilearn",
          "amount": 0.6901,
          "currency": "BTC",
          "time": "9 Dec 2022 17:20 UTC",
          "link": "https://www.blockchain.com/explorer/transactions/btc/3e084f5a53be1cf7b3120c5ab8d5d17efb120231b8a0a4d6d7ce024749c58b49"
        }
      ]
    },
  {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Rentadrone",
      "toType": "Recipient",
      "amount": 23.3100,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Rentadrone",
          "amount": 23.3100,
          "currency": "ETH",
          "time": "9 Dec 2022 17:04 UTC",
          "link": "https://etherscan.io/tx/0x4b7e03766b374455a1a85bd0bfe89a50577633e49a23b0644e0a862382d5a66b"
        }
      ]
    },




    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "recovery":true,
      "amount": 233.2,
      "currency": "ETH",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 265,
          "recovery":"UNICEF France 4%",
          "currency": "ETH",
          "time": "27 Dec 2023 11:28 UTC",
          "link": "https://etherscan.io/tx/0xc74166f4bb37258ee637494501b61df09f1a0de62a55b260f7b9c59bed4a5054"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "recovery":"UNICEF 8%",
          "amount": 233.2,
          "currency": "ETH",
          "time": "27 Dec 2023 12:34 UTC",
          "link": "https://etherscan.io/tx/0xa0b0510df17917bb6c3d6d0c81327f45f05096eb16ce10693fba352be4edebef"
        }
      ]
    },








    
        {
      "transactionType": "received",
      "from": "Xcapit",
      "fromType": "Donor",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "amount": 0.58432,
      "currency": "ETH",
      "details": [
        {
          "from": "Xcapit",
          "recipient": "UNICEF HQ",
          "amount": 0.58432,
          "currency": "ETH",
          "time": "22 Sep 2022 15:07 UTC",
          "link": "https://etherscan.io/tx/0xa54ed657d9c47be3ae9f9a79521807d7d53ff1e195479da2688f5eb379c90943"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Launchnodes",
      "toType": "Recipient",
      "amount": 32.1,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Launchnodes",
          "amount": 32.1,
          "currency": "ETH",
          "time": "11 May 2022 16:34 UTC",
          "link": "https://etherscan.io/tx/0xe555fa57b7bf86194d5f6ef2d72d9c7a5ea928638983dd657510935eec172c17"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Afinidata",
      "toType": "Recipient",
      "amount": 1.26,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Afinidata",
          "amount": 1.26,
          "currency": "BTC",
          "time": "11 May 2022 15:50 UTC",
          "link": "https://www.blockchain.com/btc/tx/b38c565a0eb70bd1ae42dc8eac14bc92c94bb0a2ede5f9e771a0bc2a20b53785"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Rentadrone",
      "toType": "Recipient",
      "amount": 73.2,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Rentadrone",
          "amount": 73.2,
          "currency": "ETH",
          "time": "21 Apr 2022 17:09 UTC",
          "link": "https://etherscan.io/tx/0xf29463209bb1e5c59d83c950849b952958b33b1344daa48345f9b4a482c3ac09#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Bookbot",
      "toType": "Recipient",
      "amount": 21.984,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Bookbot",
          "amount": 21.984,
          "currency": "ETH",
          "time": "21 Apr 2022 16:54 UTC",
          "link": "https://etherscan.io/tx/0x6ab38494d13376d93d68bb2b5f65307c686632eb28845274473ea6e9c2edf16e#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "AfriLearn",
      "toType": "Recipient",
      "amount": 1.2734,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "AfriLearn",
          "amount": 1.2734,
          "currency": "BTC",
          "time": "21 Apr 2022 21:27 UTC",
          "link": "https://www.blockchain.com/btc/tx/8143f7d49c2b3219cc0cc8c207d1f234d827a7e839ec267a9112a62933ae58b1"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Eyebou",
      "toType": "Recipient",
      "amount": 24.1404,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Eyebou",
          "amount": 24.1404,
          "currency": "ETH",
          "time": "21 Apr 2022 16:52 UTC",
          "link": "https://etherscan.io/tx/0x3556292ab98bdd0345cc739fc64d98896230eb066626c032f4851d1245e06237#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Portal Telemedicina",
      "toType": "Recipient",
      "amount": 10.342,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Portal Telemedicina",
          "amount": 10.342,
          "currency": "ETH",
          "time": "21 Apr 2022 16:50 UTC",
          "link": "https://etherscan.io/tx/0xccc47f5d05b86ee89f90aaef91ed03c2093823186a7e845069fa9932bdcc619a#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Bioverse",
      "toType": "Recipient",
      "amount": 66.0001,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Bioverse",
          "amount": 66.0001,
          "currency": "ETH",
          "time": "21 Apr 2022 16:28 UTC",
          "link": "https://etherscan.io/tx/0x9966034e0c2ca6c5850c67f7c7e6b8b53a84aca91a131368c3b862d51a45fca6#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "OTTAA",
      "toType": "Recipient",
      "amount": 69.3561,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "OTTAA",
          "amount": 69.3561,
          "currency": "ETH",
          "time": "21 Apr 2022 17:16 UTC",
          "link": "https://etherscan.io/tx/0x2a320e7772b8340930da8b73a01259aa819b59a0f2b06c294384af4c9f20e096#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Angaza Elimu",
      "toType": "Recipient",
      "amount": 70.66,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Angaza Elimu",
          "amount": 70.66,
          "currency": "ETH",
          "time": "21 Apr 2022 17:18 UTC",
          "link": "https://etherscan.io/tx/0x401f37241d88edf9ac7d9e6040f23f36be3c594da8ff0723fcc22bca00d79260#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Dronfies",
      "toType": "Recipient",
      "amount": 58,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Dronfies",
          "amount": 58,
          "currency": "ETH",
          "time": "21 Apr 2022 17:21 UTC",
          "link": "https://etherscan.io/tx/0xaa162740ac2c9e37c89e49c7cfa858412d5dbc5d3d78f54b12521988d78ee6f9#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Cirrolytix",
      "toType": "Recipient",
      "amount": 8.204,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Cirrolytix",
          "amount": 8.204,
          "currency": "ETH",
          "time": "21 Apr 2022 17:19 UTC",
          "link": "https://etherscan.io/tx/0x4eeb33d66ec13d1ba31df934c7137871cdae5c850bf2d144bcddf6351666443c#internal"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Om3ga",
      "toType": "Recipient",
      "amount": 0.604,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Om3ga",
          "amount": 0.604,
          "currency": "BTC",
          "time": "21 Apr 2022 21:20 UTC",
          "link": "https://www.blockchain.com/btc/tx/9b1be85c7d699cb1e1eb47ab399d7407e488ab61adea7564e97ec0b95a87e16e"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Weni (prev. Ilhasoft)",
      "toType": "Recipient",
      "amount": 54.16,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Weni (prev. Ilhasoft)",
          "amount": 54.16,
          "currency": "ETH",
          "time": "22 Dec 2021 16:32 UTC",
          "link": "https://etherscan.io/tx/0xd8ba361e031a7062db8ca0e456115330b7bcc9c94266b9b61c345dfb3caff849"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "StaTwig",
      "toType": "Recipient",
      "amount": 43.43,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "StaTwig",
          "amount": 43.43,
          "currency": "ETH",
          "time": "22 Dec 2021 16:31 UTC",
          "link": "https://etherscan.io/tx/0x8be58e3bbb5391117e127409b73631ff602aaebb177a0a8105612655ad940f8b"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Dymaxion Labs",
      "toType": "Recipient",
      "amount": 47.08,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Dymaxion Labs",
          "amount": 47.08,
          "currency": "ETH",
          "time": "22 Dec 2021 16:29 UTC",
          "link": "https://etherscan.io/tx/0x07d92a3b896d7743495130bb58928c00f6cdeac2ee5bb6b801883767a622c8e1"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Somleng",
      "toType": "Recipient",
      "amount": 54.17,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Somleng",
          "amount": 54.17,
          "currency": "ETH",
          "time": "22 Dec 2021 16:18 UTC",
          "link": "https://etherscan.io/tx/0xa8547a2f3fc7fd1014018ab4d40e5f2626dac2a6885f024642b123b45bb2bf64"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Atix Labs",
      "toType": "Recipient",
      "amount": 53.47,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Atix Labs",
          "amount": 53.47,
          "currency": "ETH",
          "time": "14 Dec 2021 17:51 UTC",
          "link": "https://etherscan.io/tx/0xbccfed31fe0993edf4d8f1859ba0ad605e1b93fed7518e7dfe551756a953bca8"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "BX Smart Labs",
      "toType": "Recipient",
      "amount": 9.7,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "BX Smart Labs",
          "amount": 9.7,
          "currency": "ETH",
          "time": "14 Dec 2021 17:48 UTC",
          "link": "https://etherscan.io/tx/0x4985431e95a2648d4cc00cb85fb482854ca71e7b4a974aa092c168a48ff94320"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Kotani Pay",
      "toType": "Recipient",
      "amount": 12.47,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Kotani Pay",
          "amount": 12.47,
          "currency": "ETH",
          "time": "14 Dec 2021 17:46 UTC",
          "link": "https://etherscan.io/tx/0x74deb090250e8fed461820b573a06f09c269333068e46b63acf7330e6c71ff73"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Xcapit",
      "toType": "Recipient",
      "amount": 24.04,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Xcapit",
          "amount": 24.04,
          "currency": "ETH",
          "time": "14 Dec 2021 17:40 UTC",
          "link": "https://etherscan.io/tx/0xa2160e91fcdeb3693b53dc37c3f6f60e040f26ed828aef2a5518d0559256f56d"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "recovery":true,
      "amount": 259.94,
      "currency": "ETH",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 259.94,
          "recovery":"UNICEF France 8%",
          "currency": "ETH",
          "time": "18 Oct 2021 10:45 UTC",
          "link": "https://etherscan.io/tx/0x49ab5b34528076ec8f80ddda539d1fc09060fc042e30895469ee16dee3429bdf"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "recovery":"N/A",
          "amount": 259.94,
          "currency": "ETH",
          "time": "18 Oct 2021 15:14 UTC",
          "link": "https://etherscan.io/tx/0x9a598adfa63b745a60c619d2e386cbd0e19b70fae25bee30add69ea4a3e11720"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Grassroots Economics",
      "toType": "Recipient",
      "amount": 25.11,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Grassroots Economics",
          "amount": 25.11,
          "currency": "ETH",
          "time": "15 June 2021 06:39 UTC",
          "link": "https://etherscan.io/tx/0xf26612f7f21d6f73a5cb0172265218ae1ede79f4765c5bd6191c530a3b353669"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Xcapit",
      "toType": "Recipient",
      "amount": 19.73,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Xcapit",
          "amount": 19.73,
          "currency": "ETH",
          "time": "15 June 2021 06:37 UTC",
          "link": "https://etherscan.io/tx/0x7ddab44045dfc0222e590a9b189e0d0f80ae87815604d924cb96c12024cf6423"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Leaf Global Fintech",
      "toType": "Recipient",
      "amount": 11.78,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Leaf Global Fintech",
          "amount": 11.78,
          "currency": "ETH",
          "time": "18 June 2021 06:30 UTC",
          "link": "https://etherscan.io/tx/0xd1baa35eece343105f536eaab82f776307baebb7d3d68492dfac60f5e5ebd73b"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "BX Smart Labs",
      "toType": "Recipient",
      "amount": 9.17,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "HQ",
          "amount": 9.17,
          "currency": "ETH",
          "time": "15 June 2021 06:41 UTC",
          "link": "https://etherscan.io/tx/0x31528d277a8cd70b38ad355ad2faf8086345e75a92f3ea422d1243c9fd9f4716"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Kotani Pay",
      "toType": "Recipient",
      "amount": 8.91,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Kotani Pay",
          "amount": 8.91,
          "currency": "ETH",
          "time": "15 June 2021 06:44 UTC",
          "link": "https://etherscan.io/tx/0xf430623b2c3dd13f62496225aee7ddb882b4a4f58cc0cd47bf3e629c0134f4e9"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "OS City",
      "toType": "Recipient",
      "amount": 27,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "OS City",
          "amount": 27,
          "currency": "ETH",
          "time": "27 May 2021 08:21 UTC",
          "link": "https://etherscan.io/tx/0xcd30bc97e89ef48ae159880a2e541817fc79934b39a370bc7b756d6052dbc855"
        }
      ]
    },
    {
      "transactionType": "directly received",
      "from": "Huobi",
      "fromType": "Donor",
      "intermediary": "",
      "intermediaryType": "",
      "to": "UNICEF HQ",
      "toType": "Recipient",
      "amount": 6.99,
      "currency": "BTC",
      "details": [
        {
          "from": "Huobi",
          "recipient": "UNICEF HQ",
          "amount": 6.99,
          "currency": "BTC",
          "time": "17 Feb 2021 07:14 UTC",
          "link": "https://www.blockchain.com/btc/tx/b515442b69fbaf99e85233671aedc9382f045a8cfa92062ca4a741faa3b1fddf"
        }
      ]
    },
    {
      "transactionType": "directly received",
      "from": "Huobi",
      "fromType": "Donor",
      "intermediary": "",
      "intermediaryType": "",
      "to": "UNICEF HQ",
      "toType": "Recipient",
      "amount": 0.01,
      "currency": "BTC",
      "details": [
        {
          "from": "Huobi",
          "recipient": "UNICEF HQ",
          "amount": 0.01,
          "currency": "BTC",
          "time": "12 Feb 2021 21:53 UTC",
          "link": "https://www.blockchain.com/btc/tx/b318d9a16c35630cf944a374c0432e800a7981708b0f5784c29f54a5a7ecc011"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Animoca Brands",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "amount": 167,
      "currency": "ETH",
      "details": [
        {
          "from": "Animoca Brands",
          "recipient": "UNICEF France",
          "amount": 167,
          "currency": "ETH",
          "time": "23 Dec 2020 11:36 UTC",
          "link": "https://etherscan.io/tx/0x27a4301ee1bd0006d1df69f1513f75b7b7df341d7083892f53b15912c0c3e55d"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "amount": 167,
          "currency": "ETH",
          "time": "05 Jan 2021 13:53 UTC",
          "link": "https://etherscan.io/tx/0x6d0217aa8ce7b49f00c53112b69cc504b3c68c42e72e06d7fe10585b3b7da78d"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "amount": 800,
      "currency": "ETH",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 800,
          "currency": "ETH",
          "time": "12 Oct 2020 15:28 UTC",
          "link": "https://etherscan.io/tx/0xc56c7adae5afc4d36b1ed827fb3acc40745134e607846fcee9df5a5172eef822"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "amount": 800,
          "currency": "ETH",
          "time": "12 Oct 2020 16:04 UTC",
          "link": "https://etherscan.io/tx/0x2d3fd9ea17362aa787f978b8be889e8799a2ba80c2006bdd74995b404c31fb02"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "OS City",
      "toType": "Recipient",
      "amount": 62.5,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "OS City",
          "amount": 62.5,
          "currency": "ETH",
          "time": "01 Oct 2020 17:08 UTC",
          "link": "https://etherscan.io/tx/0x881969e942992404eabfa95602f1fe197f21571933b69352bbcfcd952fefa178"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Atix Labs",
      "toType": "Recipient",
      "amount": 62.5,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Atix Labs",
          "amount": 62.5,
          "currency": "ETH",
          "time": "01 Oct 2020 17:08 UTC",
          "link": "https://etherscan.io/tx/0x7149a1e1fb2223ccbf968786ebdda078eb6c32f0953cd145925acd60f44de639"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Afinidata",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Afinidata",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 08:06 UTC",
          "link": "https://etherscan.io/tx/0x5a20f875d51ec96726ae484e9083cb3a93b0d2ced74692f473f8822b8722f20e"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Somleng",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Somleng",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 08:06 UTC",
          "link": "https://etherscan.io/tx/0xea13d93f88bc5cc1c86b0c57e60ef0bd6a6c4446c623acc2622da7fbcbfec3e8"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "to": "Ideasis",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Ideasis",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 07:57 UTC",
          "link": "https://etherscan.io/tx/0x776e0b64c76f58b7521e4e6de44026cfb6f2aa7186bc127b302544faabf83c5a"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "StaTwig",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "StaTwig",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 07:52 UTC",
          "link": "https://etherscan.io/tx/0x36a083becc6d49514c4fa8dc61ea398f01be0e681d9b79d640eccbf5bd4d80e4"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Avyantra",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Avyantra",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 07:50 UTC",
          "link": "https://etherscan.io/tx/0xe7951d7a78fa3581c48b8914c7f2c5737aeeab29989f65763c21bd1fa2134b06"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Cireha",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Cireha",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 07:44 UTC",
          "link": "https://etherscan.io/tx/0x7df0ef84451e66e26046bb896c9ea7d4ab589b97c3fa80c080d18d0b3e22be1b"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Utopic Studio",
      "toType": "Recipient",
      "amount": 125,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Utopic Studio",
          "amount": 125,
          "currency": "ETH",
          "time": "18 Jun 2020 07:41 UTC",
          "link": "https://etherscan.io/tx/0x30e928db6a6eb5d88bbdb8c19202308047683be8dd4b4f80fbe49db6c9e56cb9"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "recipient": "UNICEF HQ",
      "toType": "Recipient",
      "amount": 1200,
      "currency": "ETH",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 1200,
          "currency": "ETH",
          "time": "15 Jun 2020 05:03 UTC",
          "link": "https://etherscan.io/tx/0x7034df9c87f950902d0f3a2f431aebea854c0d2e2fa6dca8ab127362711bbd11"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "amount": 1200,
          "currency": "ETH",
          "time": "15 Jun 2020 05:47 UTC",
          "link": "https://etherscan.io/tx/0xc402f498a730b99d0105cfa3b49c483bd6f143a8c0ab082d0e2b63e8de3fd854"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Atix Labs",
      "toType": "Recipient",
      "amount": 1,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Atix Labs",
          "amount": 1,
          "currency": "BTC",
          "time": "08 Oct 2019 17:16 UTC",
          "link": "https://btc4.trezor.io/tx/8bab8a3286e7a1b2b93ed86588a98876e8de510c5e8ad3922abb2351b49b43f7"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Prescrypto",
      "toType": "Recipient",
      "amount": 49.5,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Prescrypto",
          "amount": 49.5,
          "currency": "ETH",
          "time": "08 Oct 2019 15:54 UTC",
          "link": "https://etherscan.io/tx/0x63b15553266e673f73315b93f9914a92d8208dfe8fa1d01dc119d156dc33d622"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Utopixar",
      "toType": "Recipient",
      "amount": 49.5,
      "currency": "ETH",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Utopixar",
          "amount": 49.5,
          "currency": "ETH",
          "time": "08 Oct 2019 15:57 UTC",
          "link": "https://etherscan.io/tx/0x5735e0524e936818369f2eac2ef7c917b74f00993d187ffdb738e287032e70a0"
        }
      ]
    },
    {
      "transactionType": "invested",
      "from": "UNICEF HQ",
      "fromType": "HQ",
      "intermediary": "",
      "intermediaryType": "",
      "recipient": "Atix Labs",
      "toType": "Recipient",
      "amount": 1,
      "currency": "BTC",
      "details": [
        {
          "from": "UNICEF HQ",
          "recipient": "Atix Labs",
          "amount": 1,
          "currency": "BTC",
          "time": "08 Oct 2019 17:16 UTC",
          "link": "https://btc4.trezor.io/tx/8bab8a3286e7a1b2b93ed86588a98876e8de510c5e8ad3922abb2351b49b43f7"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "amount": 1,
      "currency": "BTC",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 1,
          "currency": "BTC",
          "time": "07 Oct 2019 14:02 UTC",
          "link": "https://btc4.trezor.io/tx/8bab8a3286e7a1b2b93ed86588a98876e8de510c5e8ad3922abb2351b49b43f7"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "amount": 1,
          "currency": "BTC",
          "time": "07 Oct 2019 15:57 UTC",
          "link": "https://btc4.trezor.io/tx/396e16f8ec98dacfc56ee0b0c00aedc1a94af203050b428ae7eb08d17019846b"
        }
      ]
    },
    {
      "transactionType": "received",
      "from": "Ethereum Foundation",
      "fromType": "Donor",
      "intermediary": "UNICEF France",
      "intermediaryType": "National Committee",
      "to": "UNICEF HQ",
      "toType": "HQ",
      "amount": 100,
      "currency": "ETH",
      "details": [
        {
          "from": "Ethereum Foundation",
          "recipient": "UNICEF France",
          "amount": 100,
          "currency": "ETH",
          "time": "07 Oct 2019 13:56 UTC",
          "link": "https://etherscan.io/tx/0x659566ad82bf3fb89c54b3ce25583df0138605272381aaf7ff3883b2d9e651e0"
        },
        {
          "from": "UNICEF France",
          "recipient": "UNICEF HQ",
          "amount": 100,
          "currency": "ETH",
          "time": "07 Oct 2019 15:08 UTC",
          "link": "https://etherscan.io/tx/0xec285bbbe747076618ebcaf13f44553cbef068ee330c73a83cce8c356ef259e6"
        }
      ]
    }
  ],
  "media": [
    {
      "image": "media-1.jpg",
      "title": "The UNICEF CryptoFund",
      "link": "https://www.unicef.org/innovation/stories/unicef-cryptofund",
      "date": "23 December 2020"
    },
    {
      "image": "media-2.jpg",
      "title": "UNICEF Cryptocurrency Fund announces its largest investment of startups in developing and emerging economies",
      "link": "https://www.unicef.org/press-releases/unicef-cryptocurrency-fund-announces-its-largest-investment-startups-developing-and",
      "date": "19 June 2020"
    },
    {
      "image": "media-3.jpg",
      "title": "Leveraging Blockchain for Financial Inclusion",
      "link": "https://www.unicef.org/innovation/InnovationFund/blockchain-financial-inclusion-cohort",
      "date": "08 June 2021"
    },
    {
      "image": "media-4.jpg",
      "title": "UNICEF CryptoFund’s first institutional Bitcoin pledge, from Huobi Charity",
      "link": "https://i1.wp.com/technode.com/wp-content/uploads/2018/08/huobi-bear.png?fit=1200%2C802&ssl=1",
      "date": "06 April 2021"
    },
    {
      "image": "media-5.jpg",
      "title": "UNICEF CryptoFund Gets $1 Million in ‘Institutional Bitcoin’ Donation",
      "link": "https://decrypt.co/64013/unicef-cryptofund-1-million-institutional-bitcoin",
      "date": "06 April 2021"
    },
    {
      "image": "media-6.jpg",
      "title": "UNICEF invests USD 300K in blockchain startups",
      "link": "https://www.ledgerinsights.com/unicef-invests-300000-in-blockchain-startups/",
      "date": "09 June 2021"
    },
    {
      "image": "media-7.jpg",
      "title": "Chainlink Teams Up with UNICEF Innovation Fund to Support Blockchain Apps in Emerging Markets",
      "link": "https://www.crowdfundinsider.com/2021/05/175021-chainlink-teams-up-with-unicef-innovation-fund-to-support-blockchain-apps-in-emerging-markets/",
      "date": "06 May 2021"
    }
  ]
};

export default staticDataJson;