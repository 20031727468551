import React, { Fragment, useState, useRef } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "inline-block",
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: 0
  },
  arrowUp: {
    marginLeft: "45%",
    color: "#F2F4F6",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #F2F4F6",
    padding: 0,
    margin: 0,
    marginTop: -10
  },
  arrowDown: {
    marginLeft: "45%",
    color: "#374EA2",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "10px solid #374EA2",
    padding: 0,
    margin: 0,
  },

}));

export default function MenuPopper(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(props.anchorRef);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <div
        ref={anchorRef}
        style={{ display: "inline-block" }}
        aria-haspopup="true"
        onMouseOver={handleToggle}

      >
        {props.button}
      </div>

      <Popper
        placement={props.placement}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
        className={props.className}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                props.placement === "bottom" ? "top" : "bottom",
            }}
          >
            <Paper
              elevation={1}
              className={classes.paper}
              onMouseLeave={handleClose}
            >
              {props.arrowUp ?
                  <>
                    <div className={classes.arrowUp}>
                    </div>
                    <ClickAwayListener onClickAway={handleClose}>
                      {props.children}
                    </ClickAwayListener>
                  </>
                  :
                  <>
                    <ClickAwayListener onClickAway={handleClose}>
                      {props.children}
                    </ClickAwayListener>
                    <div className={classes.arrowDown}>
                    </div>
                  </>
                }
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
