import { createContext } from "react";
//import staticData from "../data/data";

const AppContext = createContext({
  btcRate: 0,
  ethRate: 0,
  accounts: [],
  transactions: [],
  staticData: [],
  btcDonations: 0,
  ethDonations: 0,
  btcInvestments: 0,
  ethInvestments: 0,
  donorCount: 0,
  startupCount: 0,
});

export default AppContext;
