import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

export default function ({
  startIcon,
  endIcon,
  onClick,
  float,
  children,
  style,
  disabled,
  primary = true,
    className
}) {
  const primaryStyles = makeStyles((theme) => ({
    containedButton: {
      minWidth: 160,
      height: 46,
      fontFamily: '"Prompt", sans-serif',
      fontSize: 16,
      fontWeight: 400,
      textAlign: "center",
      textTransform: "unset",
      color: "#ffffff",
      borderRadius: 0,
      border: "solid 2px",
      borderColor: theme.palette.primary.main,
      padding: "15px 20px 15px 20px",
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        opacity: 0.9,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        opacity: 0.9,
      },
      "&:focus": {
        boxShadow: "none",
        opacity: 0.9,
      },
    },
  }));
  const secondaryStyles = makeStyles((theme) => ({
    containedButton: {
      minWidth: 148,
      height: 46,
      fontFamily: '"Prompt", sans-serif',
      fontSize: 16,
      fontWeight: 400,
      textAlign: "center",
      textTransform: "unset",
      color: "#ffffff",
      borderRadius: 0,
      padding: "15px 20px 15px 20px",
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
        opacity: 0.9,
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "none",
        opacity: 0.9,
      },
      "&:focus": {
        boxShadow: "none",
        opacity: 0.9,
      },
    },
  }));

  const classes = primary ? primaryStyles() : secondaryStyles();
  return (
    <Button
      disableRipple={true}
      disabled={disabled}
      variant="contained"
      color="secondary"
      className={`${classes.containedButton} ${className}`}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      onClick={onClick}
      style={{ float: float ? float : null, ...style }}
    >
      {children}
    </Button>
  );
}
