const mockData = require("./mockdata.json");

export default async () => {
  let res;
  let data = mockData;
  try {
    res = await fetch("/rest/visualization");
    data = await res.json();
  } catch (e) {
    console.log('Error while fetching data:')
    console.log(e);
  }

  return data;
};
