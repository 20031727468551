import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TransactionTable from "./Table";
import MobileTransactionTable from "./MobileTable";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F4F6",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      // paddingLeft: 200,
      // paddingRight: 270,
      paddingTop: 100,
    },
  },
  h1: {
    fontSize: 26,
    lineHeight: 1.46,
    fontWeight: 400,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },

  description: {
    fontFamily: "'Roboto', sans-serif",
    maxWidth: 745,
    fontSize: 18,
    lineHeight: "30px",
    fontWeight: 400,
    marginBottom: 100,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      lineHeight: "34px",
    },
  },
}));

export default function TransactionSection() {
  const classes = useStyles();

  return (
    <section id="transactions" className={classes.root}>
      <Container maxWidth={"lg"}>
        <Grid container>
          <Grid item xs={12} md={11} style={{ margin: "0 auto" }}>
            <h1 className={classes.h1}>The transaction process</h1>
            <p className={classes.description}>
              UNICEF is able to receive cryptocurrency donations directly from a
              donor or via four official UNICEF fundraising entities, also known
              as National Committees (NatComs). Through these four National
              Committees - Australia, France, New Zealand, and the United States
              - donors are able to contribute to the CryptoFund in
              cryptocurrency.
            </p>
            <h1 className={classes.h1}>Transactions</h1>
            <Hidden smDown>
              <TransactionTable />
            </Hidden>
            <Hidden mdUp>
              <MobileTransactionTable />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
