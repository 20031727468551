import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FilledButton from "../Buttons/Filled";
import teaching from "./teaching.svg";
import clock from "./Clock.png";
import generalStyle from "../../util/style";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.up("md")]: {
      paddingTop: 200,
      paddingBottom: 100,
    },
  },
  card: {
    marginTop: 120,
    marginBottom: 120,
  },
  message: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    fontSize: 26,
    padding: 28,
    [theme.breakpoints.up("md")]: {
      padding: "50px 52px 0px 51px",
      maxHeight: 425,
      marginTop: -50,
      zIndex: 10,
    },
  },
  button: {
    marginTop: 30,
  },
  imageHolder: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "-9em",
    },
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      height: 424,
      maxWidth: "120%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  description: {
    fontSize: 18,
    [theme.breakpoints.down("md")]:{
      fontSize: 16
    }
  },
  clock: {
    width: 28,
    height: 33,
    marginRight: 13,
  },
}));

function Application() {
  const classes = useStyles();
  const style = generalStyle();

  function navFunding() {
    window.location = "/funding"
  }
  return (
      <Grid container className={classes.card}>
        <Grid item xs={12} md={6} className={classes.message}>
          <p style={{ margin: 0 }} className={style.title}>
            UNICEF Funding Opportunity for Tech Startups
          </p>
          <Grid container style={{marginTop: 10}}>
            <Grid item style={{marginTop: 10}}>
              <img src={clock} className={classes.clock}/>
            </Grid>
            <Grid item>
              <p className={classes.description}>
                Deadline: 31 January 2021
              </p>
            </Grid>
          </Grid>
          <FilledButton style={{ marginTop: 30, marginBottom: 2 }} onClick={navFunding}>
            Apply for funding
          </FilledButton>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageHolder}>
          <img src={teaching} className={classes.image} />
        </Grid>
      </Grid>
  );
}

export default function (props) {
  const classes = useStyles();

  return (
      <Container maxWidth="lg">
        <Grid container className={classes.root}>
          <Grid item xs={12} md={10} style={{ margin: "0 auto" }}>
            <Application />
          </Grid>
        </Grid>
      </Container>
  );
}
