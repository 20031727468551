import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hero from "../Hero";
import How from "./How";

import funding from "./Funding.png";
import {Container} from "@material-ui/core";
import Application from "../Home/Application";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  hero: {
    paddingBottom: 100,
  },
}));

export default function Funding() {
  const classes = useStyles();
  const title = "Get funding";
  const description =
    "The CryptoFund provides up to USD 100K equity-free funding, in bitcoin or ether, to open source frontier tech solutions showing promising results. In addition to receiving crypto assets, projects receive mentorship on various topics to develop their solutions.";
    useEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    });

  return (
      <>
        <Container maxWidth={"lg"}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <div className={classes.hero}>
                <Hero title={title} description={description} image={funding} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <How />
        {/*<Apply />*/}
        <Application/>
      </>
  );
}
