import React, { useEffect, useState } from "react";
import theme from "./theme";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./app.css";
import Home from "./components/Home";
import About from "./components/About";
import Funding from "./components/Funding";
import Projects from "./components/Projects";
import Track from "./components/Track";
import Media from "./components/Media";
import Layout from "./components/Layout";
import AppContext from "./context/AppContext";
import { getData } from "./actions";
import staticDataJson from "./data/data";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]:{
      // width: "100vw",
      // overflowX: "hidden",
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function getAddressObject(accounts, type) {
  return accounts
    .filter((acct) => acct.type === type)
    .map((acct) => acct.addresses)
    .reduce((addrs, acct) => {
      return addrs.concat(acct);
    }, [])
    .reduce((donors, addrs) => {
      donors.push(addrs.address);
      return donors;
    }, []);
}

function getDonorAmounts(transactions, symbol) {
  return transactions
    .filter((tx) => {
      return tx.isUnicef && tx.received && tx.symbol === symbol;
    })
    .reduce((sum, tx) => {
      return sum + tx.amount;
    }, 0);
}

function getInvestmentAmounts(transactions, symbol) {
  return transactions
    .filter((tx) => {
      return tx.isUnicef && tx.sent && tx.symbol === symbol
      // filter out transactions between the two UNICEF-owned wallets, v2 (from) and v3 (to)
        && !(tx.from === "0x61ace53098d226e77cd26ae26e2c377fb9cb7657" && tx.to === "0xb1be5e4c68b16c5b434b24d55c252e5852f7aa29")
    })
    .reduce((sum, tx) => {
      return sum + tx.amount;
    }, 0);
}

function getUnique(array, value){
  let uniqueArray = [];
  let i;
  // Loop through array values
  for(i=0; i < array?.length; i++){
    if(uniqueArray.indexOf(array[i][value]) === -1) {
      uniqueArray.push(array[i][value]);
    }
  }
  return uniqueArray;
}

function countAccounts(accounts, type, value) {
  const duplicated = accounts?.filter((acct) => {
    return acct.transactionType === type;
  });

  return getUnique(duplicated, value).length
}

export default function JuniperCustody() {
  const classes = useStyles();
  const [state, setState] = useState({
    btcRate: 0,
    ethRate: 0,
    accounts: [],
    transactions: [],
    staticData: []
  });

  useEffect(() => {
    const init = async () => {
      const staticData = staticDataJson;
      const data = await getData();
      const donorCount = countAccounts(staticData.transactions, "directly received", "from");
      const startupCount = countAccounts(staticData.transactions, "invested", "to");
      const donorAddresses = getAddressObject(data.accounts, "donor");
      const startupAddresses = getAddressObject(data.accounts, "payee");
      const btcDonations = getDonorAmounts(data.transactions, "BTC");
      const ethDonations = getDonorAmounts(data.transactions, "ETH");
      const btcInvestments = getInvestmentAmounts(data.transactions, "BTC");
      const ethInvestments = getInvestmentAmounts(data.transactions, "ETH");
      setState({
        ...data,
        staticData,
        donorAddresses,
        startupAddresses,
        btcDonations,
        ethDonations,
        btcInvestments,
        ethInvestments,
        donorCount,
        startupCount,
      });
    };
    init();
  }, []);
  const {
    btcRate,
    ethRate,
    transactions,
    accounts,
    staticData,
    btcDonations,
    ethDonations,
    btcInvestments,
    ethInvestments,
    donorCount,
    startupCount,
  } = state;

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <AppContext.Provider
              value={{
                btcRate,
                ethRate,
                transactions,
                accounts,
                staticData,
                btcDonations,
                ethDonations,
                btcInvestments,
                ethInvestments,
                donorCount,
                startupCount,
              }}
            >
              <Layout>
                <Switch>
                  <Route exact path="/home">
                    <Home />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="/funding">
                    <Funding />
                  </Route>
                  <Route path="/projects">
                    <Projects />
                  </Route>
                  <Route path="/track">
                    <Track />
                  </Route>
                  <Route path="/media">
                    <Media />
                  </Route>
                  <Redirect from="*" to="/home" />
                </Switch>
              </Layout>
            </AppContext.Provider>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </div>
  );
}
