import React, {useContext, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hero from "./Hero";
import { SummarySection } from "../Summary";
import ScrollSection from "./ScrollSection";
import Application from "./Application";
import Why from "./Why";
import Latest from "./Latest";
import Donors from "./Donors";
import Media from "./Media";
import Contact from "./Contact";
import AppContext from "../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
  },
  hero: {
    marginTop: 140,
    marginBottom: 100,
    [theme.breakpoints.up("md")]: {
      marginTop: 240,
    },
  },
}));

export default function About() {
  const classes = useStyles();

  const {
    btcDonations,
    ethDonations,
    btcInvestments,
    ethInvestments,
    donorCount,
    startupCount,
  } = useContext(AppContext);

  const donorData = {
    total: donorCount,
    label: "donors",
    btc: btcDonations,
    eth: ethDonations,
  };
  const investorData = {
    total: startupCount,
    label: "investments",
    btc: btcInvestments,
    eth: ethInvestments,
  };

  const description =
    "The CryptoFund is a new financial vehicle allowing UNICEF to receive, hold, and disburse cryptocurrency - a first for the UN. This cryptocurrency-denominated fund is a part of UNICEF’s Venture Fund. The investments are made in bitcoin and ether.";

    useEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    });
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.hero}>
        <Hero
          style={{ color: "white" }}
          subtitle={"subtitle"}
          description={description}
          buttons={<button />}
          hide={true}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 300 }}>
        <SummarySection primaryData={donorData} secondaryData={investorData} />
      </Grid>
      <Grid item xs={12}>
        <ScrollSection />
      </Grid>
      <Grid item xs={12}>
        <Application />
      </Grid>
      <Grid item xs={12}>
        <Why />
      </Grid>
      <Grid item xs={12}>
        <Latest />
      </Grid>
      <Grid item xs={12}>
        <Donors />
      </Grid>
      <Grid item xs={12}>
        <Media />
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Investments />*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <Contact />
      </Grid>
    </Grid>
  );
}
