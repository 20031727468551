import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import BrandLogo from "./BrandLogo";
import CloseIcon from "@material-ui/icons/Close";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";

// function HideOnScroll(props) {
//   const { children } = props;
//   const trigger = useScrollTrigger();
//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

const useStyles = makeStyles((theme) => ({
  topAppBar: {
    backgroundColor: "#374EA2",
    position: "fixed",
    width: "100%",
    top: 0,
    //zIndex: 999
  },
  appBar: {
    top: 70,
    position: "absolute",
    backgroundColor: "#374EA2",
    width: "100%",
  },
  toolbar: {
    minHeight: "unset",
    padding: 0,
  },
  brandImage: {},
  hamburger: {
    color: "#000000",
    position: "absolute",
    right: 20,
  },
  menu: {
    height: 70,
  },
  menuIcon: {
    position: "absolute",
    fontSize: 36,
    right: 14,
    fontWeight: 200,
    color: "white",
  },
  brand: {
    paddingTop: 19,
  },
  item: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    color: "white",
    textDecoration: "none",
    minHeight: 70,
    borderBottom: "solid 3px rgba(0,0,0,0)",
    "&:hover": {
      color: "white",
      textDecoration: "underline",
    },
  },
  activeItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,

    color: "#374EA2",
    minHeight: 70,
    textDecoration: "none",
    "&:hover": {
      color: "#374EA2",
    },
  },
  fullList: {
    display: "flex",
    flexDirection: "column",

    width: "auto",
    height: "100%",
  },
  close: {
    display: "flex",
    alignItems: "center",
    padding: ".75em",
    paddingLeft: 24,
  },
  path: {
    height: 70,
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  activePath: {
    height: 70,
    backgroundColor: "white",
  },
  dailyPrice: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    minHeight: 80,
    textDecoration: "none",
    color: "#1cabe2",
  },
  menuPopper: {
    width: 357,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    padding: 32,
    fontSize: 20,
    lineHeight: 2.1,
  },
  closeIcon: {
    position: "absolute",
    right: 14,
    fontSize: 36,
    fontWeight: 200,
  },
  brandLogo: {
    display: "inline",
    marginRight: "50%",
  },
  mobileItem: {
    paddingLeft: 24,
  },
  activeMobileItem: {
    paddingLeft: 24,
    backgroundColor: theme.palette.primary.main,
  },
  activeMobileItemText: {
    fontSize: 20,
    lineHeight: 2.1,
    fontWeight: 500,
    color: "#ffffff",
  },
  mobileItemText: {
    fontSize: 20,
    lineHeight: 2.1,
    fontWeight: 500,
    color: "#000000",
  },
  socialLinks: {
    paddingTop: 60,
    paddingLeft: 9,
  },
  icon: {
    fontSize: 28,
    margin: ".5em",
    color: theme.palette.primary.main,
  },
}));

const desktopLinks = [
  {
    title: "Media",
    path: "/media",
  },
  {
    title: "Track funds",
    path: "/track",
  },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "Get funding",
    path: "/funding",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Home",
    path: "/home",
  },
];

const mobileLinks = [
  {
    title: "Home",
    path: "/home",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Get funding",
    path: "/funding",
  },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "Track funds",
    path: "/track",
  },
  {
    title: "Media",
    path: "/media",
  },
];

function DesktopNav(props) {
  const classes = useStyles();
  const [active, setActive] = useState(null);

  useEffect(() => {
    desktopLinks.forEach((link) => {
      if (window.location.pathname === link.path) {
        setActive(link.path);
      }
    });
  }, []);
  return (
    <Hidden smDown>
      <Container
        maxWidth={"lg"}
        style={{ backgroundColor: "#374EA2", height: "70px", paddingRight: 76 }}
      >
        <Grid container direction="row-reverse">
          {/*<Link to="#" style={{ textDecoration: "none" }}>*/}
          {/*  <MenuPopper*/}
          {/*    placement={"center"}*/}
          {/*    button={*/}
          {/*      <Grid item className={classes.dailyPrice}>*/}
          {/*        <MonetizationOnIcon style={{ marginRight: 10 }} />*/}
          {/*        Daily crypto price*/}
          {/*      </Grid>*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Grid container className={classes.menuPopper}>*/}
          {/*      <Grid item xs={12}>*/}
          {/*        1 bitcoin (BTC) = {usdFormatter(btcRate || 0)} USD*/}
          {/*      </Grid>*/}
          {/*      <Grid item xs={12}>*/}
          {/*        1 ether (ETH) = {usdFormatter(ethRate || 0)} USD*/}
          {/*      </Grid>*/}
          {/*      <Grid item xs={12}>*/}
          {/*        <small>Prices calculated at 12:00 UTC</small>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </MenuPopper>*/}
          {/*</Link>*/}
          {desktopLinks.map((link, index) => {
            const isActivePath = link.path === active;
            return (
              <Link
                key={index}
                to={link.path}
                className={isActivePath ? classes.activePath : classes.path}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setActive(link.path);
                }}
              >
                <Grid
                  item
                  className={isActivePath ? classes.activeItem : classes.item}
                  key={index}
                >
                  {link.title}
                </Grid>
              </Link>
            );
          })}
          <Grid item style={{ flexGrow: 1 }} className={classes.brand}>
            <BrandLogo />
          </Grid>
        </Grid>
      </Container>
    </Hidden>
  );
}

function MobileNav() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(null);

  useEffect(() => {
    mobileLinks.forEach((link) => {
      if (window.location.pathname === link.path) {
        setActive(link.path);
      }
    });
  }, [window.location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };
  return (
    <Hidden mdUp>
      <Grid container className={classes.menu}>
        <Grid item xs={12} className={classes.close}>
          <BrandLogo className={classes.brandLogo} />
          <IconButton
            className={classes.hamburger}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon color="inherit" className={classes.menuIcon} />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer anchor={"top"} open={open} onClose={toggleDrawer(false)}>
        <div
          className={classes.fullList}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className={classes.close}>
            <BrandLogo className={classes.brandLogo} />
            <CloseIcon className={classes.closeIcon} />
          </div>
          <List style={{ flexGrow: 1 }}>
            {mobileLinks.map((link, index) => {
              const isActivePath = link.path === active;
              return (
                <Link
                  to={link.path}
                  key={index}
                  style={{ textDecoration: "none", fontWeight: 600 }}
                >
                  <ListItem
                    button
                    key={link.title}
                    className={
                      isActivePath
                        ? classes.activeMobileItem
                        : classes.mobileItem
                    }
                  >
                    <span
                      className={
                        isActivePath
                          ? classes.activeMobileItemText
                          : classes.mobileItemText
                      }
                    >
                      {link.title}
                    </span>
                  </ListItem>
                </Link>
              );
            })}
          </List>
          <div>
            <Grid item xs={12} className={classes.socialLinks}>
              <EmailIcon className={classes.icon} />
              <TwitterIcon className={classes.icon} />
            </Grid>
          </div>
        </div>
      </Drawer>
    </Hidden>
  );
}

export default function NavBar(props) {
  const classes = useStyles();
  const refScroll = useRef();
  const [top, setTop] = useState(true);
  useEffect(() => {
    document.getElementById("root").addEventListener("scroll", (ev) => {
      if (refScroll.current) {
        if (document.getElementById("root").scrollTop > 70) {
          setTop(false);
        } else {
          setTop(true);
        }
      }
    });
  }, []);
  return (
    // <HideOnScroll {...props}>
    <AppBar
      elevation={0}
      position={"absolute"}
      className={top ? classes.appBar : classes.topAppBar}
      ref={refScroll}
    >
      <Toolbar className={classes.toolbar}>
        <MobileNav />
        <DesktopNav />
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
  );
}
