import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MuiTableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core";

const TableCellModified = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);
const useStyles = makeStyles((theme) => ({
  label: {
    font: '"Roboto", sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 2,
    cursor: "pointer",
    paddingLeft: 30,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  bold: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "From",
  },
  { id: "recipient", numeric: true, disablePadding: false, label: "Recipient" },
  {
    id: "crypto",
    numeric: true,
    disablePadding: false,
    label: "Crypto amount",
  },
  { id: "txType", numeric: true, disablePadding: false, label: "Type" },
  { id: "txTime", numeric: true, disablePadding: false, label: "Time" },
];

export default function TableHeader(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{border : 0}}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCellModified
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            onClick={createSortHandler(headCell.id)}
            className={classes.label}
          >
            {headCell.label}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
            >
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCellModified>
        ))}
      </TableRow>
    </TableHead>
  );
}
